<div class="container-fluid">


    <div class="timeline-container" *ngIf="showTimeline">
        <p-timeline [value]="data" layout="horizontal" [align]="'bottom'">
            <ng-template pTemplate="marker" let-note>
                <div class="timeline-marker" [pTooltip]="getTooltipContent(note)" tooltipPosition="top"
                    [tooltipOptions]="{ showDelay: 500, appendTo: 'body' }">
                    <fa-icon *ngIf="note.NoteTypeName == 'Alert Note'" [icon]="faCircleClosed" class="text-danger"
                        size="2x"></fa-icon>
                    <fa-icon *ngIf="note.NoteTypeName != 'Alert Note'" [icon]="faCircleOpen" size="2x"
                        class="text-success"></fa-icon>
                </div>
            </ng-template>
        </p-timeline>
    </div>

    <ng-template #customTooltip let-note="note">
        <div class="card note-tooltip-card">
            <div class="card-body">
                <h5 class="card-title">{{ note.NoteType.Name }}</h5>
                <h6 class="card-subtitle mb-2 text-muted">{{ note.CreatedByUserID }}</h6>
                <p class="card-text">
                    <small>{{ note.DateCreated | date: 'short' }}</small>
                </p>
                <div class="card-text"><span [innerHtml]="note.NoteBodyHtml"></span></div>
            </div>
        </div>
    </ng-template>


    <div class="row">
        <app-material-grid [data]="data" [columns]="columns" [loading]="isLoading" [showEditButton]="true"
            (editClicked)="openEditModal($event)"></app-material-grid>
    </div>

    <ng-template #editModal>

    </ng-template>

</div>