<div class="modal-header">
  <h4 class="modal-title pull-left">Add Salesforce Implementation Assignment</h4>

</div>
<div class="modal-body">
  <div class="row">
    <div class="form-group">
      <label for="templateName">Template Name:</label>
      <span *ngIf="template" class="form-control">{{ template.Name }}</span>
    </div>

  </div>
  <div class="row">
    <div class="form-group">
      <label for="implementationTypeahead">Implementation:</label>
       <input name="implementationTypeahead" required="true" [(ngModel)]="selectedImplementationName" (typeaheadOnSelect)="impSelected($event.item)" [typeahead]="implementations" placeholder="Type to select an implementation"
        typeaheadOptionField="name" class="form-control" required="true">
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary pull-right" (click)="doSubmit()">Submit</button>
  <button type="button" class="btn btn-secondary pull-right" aria-label="Close" (click)="modalRef?.hide()">
    Close
  </button>
</div>
