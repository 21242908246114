import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  Attachment,
  AttachmentService,
} from 'src/app/services/attachment.service';
import { Toast, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-attachment-modal',
  templateUrl: './add-attachment-modal.component.html',
  styleUrls: ['./add-attachment-modal.component.scss'],
})
export class AddAttachmentModalComponent implements OnInit {
  ImplementationEstimate?: any;
  associationType: string;
  associationId: number | null;

  attachmentForm: FormGroup;
  file: File | null = null;
  attachmentTypes: any = [];

  @Output() attachmentAdded: EventEmitter<any> = new EventEmitter();
  @Output() uploadInProgress: EventEmitter<boolean> = new EventEmitter();
  toastRef: import("ngx-toastr").ActiveToast<any>;

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private attachmentService: AttachmentService,
    private toaster: ToastrService
  ) {
    // Initialize form with FormBuilder
    this.attachmentForm = this.formBuilder.group({
      file: [null, Validators.required],
      AttachmentTypeID: [null, Validators.required],
      description: [''],
    });
  }

  ngOnInit(): void {
    // Fetch attachment types on init
    this.attachmentService.getAttachmentTypes().subscribe((types) => {
      this.attachmentTypes = types;
    });
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.file = input.files[0];
      this.attachmentForm.patchValue({ file: this.file });
    } else {
      this.file = null;
      this.attachmentForm.patchValue({ file: null });
    }
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  doSave() {
    if (this.attachmentForm.valid && this.file) {
      const formData: FormData = new FormData();

      formData.append('file', this.file);

      formData.append(
        'AttachmentTypeID',
        this.attachmentForm.get('AttachmentTypeID')?.value
      );
      formData.append(
        'Description',
        this.attachmentForm.get('description')?.value
      );
      formData.append(
        'ImplementationEstimateID',
        this.ImplementationEstimate.ImplementationEstimateID
      );

      if (this.associationType == 'note')
        formData.append('NoteID', this.associationId.toString());

      if (this.associationType == 'calendar')
        formData.append('CalendarEventID', this.associationId.toString());

      this.toastRef = this.toaster.info(`Uploading ${this.file.name} in background`, null, {
        timeOut: 0, // Disable auto dismiss
        extendedTimeOut: 0, // Disable extended timeout
        closeButton: true, // Show the close button
        tapToDismiss: false // Prevent dismissing on tap
      });

      this.bsModalRef.hide();

      this.uploadInProgress.emit(true);
      this.attachmentService
        .uploadAttachment(formData)
        .subscribe((attachment) => {
          
          this.toastRef.toastRef.close();
          
          this.toaster.success(
            `Attachment "${this.file.name}" uploaded successfully.`
          );
          this.attachmentAdded.emit(attachment);
          this.uploadInProgress.emit(false);
        }),
        (error) => {
          this.toaster.error(`Attachment "${this.file.name}" upload failed.`);
          this.uploadInProgress.emit(false);
        };
    }
  }
}
