<ngx-datatable
  class="bootstrap"
  [rows]="rowData"
  [columnMode]="'force'"
  [headerHeight]="50"
  [sortType]="sortType"
  [rowHeight]="75"
  [scrollbarV]="true"
  style="min-height: 75vh; overflow-wrap:break-word;"
>
  <ngx-datatable-column name="Date" prop="EntryDate" [width]="25">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <strong>{{ row.EntryDate | date: 'MM/dd/yyyy' }}</strong>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Implementation | Category" [minWidth]="100" [flexGrow]="400" [sortable]="false">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <div class="w-100 justify-content-between">
        <p class="mb-1">{{ row.ImplementationEstimate?.SFImplementationName ?? row.InternalTimeSubcategory?.Name }}</p>
        <em>{{ row.Title }}</em>
        <p class="wrap-text">{{ row.Note }}</p>
      </div>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Role" prop="Role.Name" [width]="25">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.Role?.Name ?? '--' }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Deliverable" prop="Deliverable.Name">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.Deliverable?.Name ?? '--' }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Hours" prop="TimeSpent" [width]="25">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.TimeSpent }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Actions" [sortable]="false">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <div class="d-flex justify-content-center">
        <button type="button" class="btn btn-sm btn-secondary m-2" (click)="openTimeEntryEditModal(row)" [disabled]="row.TimesheetID">
          <fa-icon *ngIf="!row.TimesheetID && showEditButton" [icon]="faPen"></fa-icon>
          <fa-icon *ngIf="row.TimesheetID && showEditButton" [icon]="faLock"></fa-icon>
        </button>
        <button *ngIf="showCopyButton" type="button" class="btn btn-sm btn-dark m-2" (click)="copyEntry(row)"><fa-icon [icon]="faCopy"></fa-icon></button>
        <button *ngIf="!row.TimesheetID && showDeleteButton" type="button" class="btn btn-sm btn-danger m-2" (click)="deleteEntry(row)" [disabled]="row.TimesheetID"><fa-icon [icon]="faTrash"></fa-icon></button>
      </div>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>
