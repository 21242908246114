<div class="container-fluid">
  <div class="row">
    <h3>Internal Time Configuration</h3>
  </div>
  <div class="row">
    <div class="col d-flex justify-content-end mt-1">
      <!-- <button type="button" class="btn btn-primary"><fa-icon [icon]="faPlus"></fa-icon> Category</button> -->
    </div>
    <div class="row">
      <div class="table-responsive table-wrapper-scroll-y my-custom-scrollbar">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let category of categories">
              <td>{{ category.InternalTimeCategoryID }}</td>
              <td>
                <accordion>
                  <accordion-group [heading]="category.Name">
                    <ul class="list-group-flush">
                      <li class="list-group-item" *ngFor="let subcategory of category.InternalTimeSubcategories">{{ subcategory.Name }}</li>
                    </ul>
                  </accordion-group>
                </accordion>
              <td class="align-middle text-center"><button type="button" class="btn btn-secondary" (click)="openConfigModal(category)"><fa-icon [icon]="faPen"></fa-icon> Edit</button></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#ffeb3b" type="pacman" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
</div>


<!-- <ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add Category</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="form-inline">
          <label for="Name">Name:</label>
          <input class="form-control" type="text" name="Name" [(ngModel)]="categoryName" required>
      </div>

    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary pull-right" (click)="createCategory(categoryName)" [disabled]="categoryName.length === 0">Submit</button>
    <button type="button" class="btn btn-secondary pull-right" aria-label="Close" (click)="modalRef?.hide()">
      Close
    </button>
  </div>
</ng-template> -->
