import { Component, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faSalesforce } from '@fortawesome/free-brands-svg-icons';
import { faTrash, faPen, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatColumn } from 'src/app/grid/material-grid/material-grid.component';
import { DataService } from 'src/app/services/data.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-implementations-list-mat',
  templateUrl: './implementations-list-mat.component.html',
  styleUrls: ['./implementations-list-mat.component.scss']
})
export class ImplementationsListMatComponent {

  implementations: any;
  faTrash = faTrash;
  faPen = faPen;
  faTriangleExclamation = faTriangleExclamation;
  faSalesforce = faSalesforce;
  modalRef?: BsModalRef;
  gridLoading: boolean = false;
  gridColumns: MatColumn[] = [];

  constructor(
    private dataService: DataService,
    private spinnerService: NgxSpinnerService,
    private modalService: BsModalService,
    private utilityService: UtilityService,
    private router: Router

  ) {}

  ngOnInit(): void {
    this.modalService.onHide.subscribe(() => this.loadData());
    this.loadData();

  }

  loadData(): void {
    this.spinnerService.show();
    this.gridLoading = true;
    this.dataService.getImplementationEstimateList().subscribe((result) => {
      this.implementations = result;
      this.gridColumns = this.utilityService.getGridColumns(this.implementations.GridConfig.GridColumns);
      setTimeout(() => this.spinnerService.hide(), 500);
    });
  }

  showDeleteAssignementModal(template: TemplateRef<any>, $event) {
    console.log($event);
  }

  showDeleteAssignmentModal(template: TemplateRef<any>, id: any) {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' })
    this.modalRef.id = id;
  }

  handleHyperlinkClicked($event) {
    if ($event.column.key == 'SFImplementationName') {
      this.router.navigate(['/implementation-detail',$event.rowData.ImplementationEstimateID]);
    }
    
  }


  deleteImplementation(): void {
    const id = this.modalRef?.id;

    this.dataService.deleteImplementationEstimate(id).subscribe(x => {
      this.modalRef.hide();
      this.implementations = x;
    }, err => {
      alert(err.error);
    });
  }
}
