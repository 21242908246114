<div class="container-fluid">
  <div class="row flex-nowrap">
    <div class="col-auto px-0">
      <app-menu-bar></app-menu-bar>
       
    </div>
    <main class="col ps-md-2 pt-2">
      
      <!-- <button class="btn btn-secondary btn-sm" (click)="sidebarMenu.toggleCollapse();" [tooltip]="isCollapsed ? 'Expand Menu' : 'Collapse Menu'">
        <fa-icon [icon]="isCollapsed ? faAngleDoubleRight : faAngleDoubleLeft"></fa-icon>
      </button> -->
      
      <router-outlet *ngIf="!isIframe"></router-outlet>
      
    </main>
  </div>
</div>



  <!-- This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
<!-- <router-outlet *ngIf="!isIframe"></router-outlet> -->


