import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentDate'
})
export class MomentDatePipe implements PipeTransform {
  transform(value: string | Date, format: string = 'MM-DD-YYYY h:mm A'): string {
    if (!value) return '';

    // Convert the value to a moment object
    let date: moment.Moment;

    if (typeof value === 'string') {
      // Parse string values as UTC
      date = moment.utc(value);
    } else {
      // For Date objects, treat as local time
      date = moment(value);
    }

    // Convert UTC date to local time
    date = date.local();

    // Check if the date is valid
    if (!date.isValid()) {
      return '';
    }

    // Format the date
    return date.format(format);
  }
}
