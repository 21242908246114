<table class="table">
  <thead>
    <tr>
      <th>Date</th>
      <th>Implementation | Category</th>
      <th>Role</th>
      <th>Deliverable</th>
      <th>Hours</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let groupKey of groupKeys(); let index = index">
      <tr (click)="toggleGroup(groupKey)" style="cursor:pointer; border:none;" scope="row" [ngClass]="{'odd-row': index % 2 === 0, 'even-row': index % 2 !== 0}">

        <td colspan="2">
          <div class="group-header">
            <span style="margin-right: 10px">
              <fa-icon [icon]="currentExpandedGroup === groupKey ? faChevronDown : faChevronRight"></fa-icon>
            </span>
            <span><b>{{ groupKey | date: 'MM/dd/yyyy' }}</b></span>
            <span style="margin-left:10px">Total Hours: {{ calculateTotalHours(rowGroups[groupKey].items) | number }}</span>
          </div>
        </td>

        <td colspan="4">
        </td>

      </tr>
      <ng-container *ngIf="currentExpandedGroup === groupKey">

        <tr *ngFor="let entry of rowGroups[groupKey].items; let index2 = index" scope="row" style="border: none;" [ngClass]="{'odd-row': index2 % 2 === 0, 'even-row': index2 % 2 !== 0}">
          <td></td>
          <td>
            <div>
              <div class="d-flex w-100 justify-content-between">
                <h6 class="mb-1">{{ entry.ImplementationEstimate?.SFImplementationName ??
                  entry.InternalTimeSubcategory?.Name }}</h6>
              </div>
              <p>{{ entry.Title }}</p>
              <p><small>{{ entry.Note }}</small></p>
            </div>
          </td>
          <td>{{ entry.Role?.Name ?? '--' }}</td>
          <td>{{ entry.Deliverable?.Name ?? '--' }}</td>
          <td>{{ entry.TimeSpent }}</td>
          <td>
            <div class="d-flex justify-content-center">
              <button type="button" class="btn btn-sm btn-secondary m-2" (click)="openTimeEntryEditModal(entry)"
                [disabled]="entry.TimesheetID">
                <fa-icon *ngIf="!entry.TimesheetID" [icon]="faPen"></fa-icon>
                <fa-icon *ngIf="entry.TimesheetID" [icon]="faLock"></fa-icon>
              </button>
              <button type="button" class="btn btn-sm btn-dark m-2" (click)="copyEntry(entry)"><fa-icon
                  [icon]="faCopy"></fa-icon></button>
              <button type="button" class="btn btn-sm btn-danger m-2" (click)="deleteEntry(entry)"
                [disabled]="entry.TimesheetID"><fa-icon [icon]="faTrash"></fa-icon></button>
            </div>
          </td>
        </tr>
      </ng-container>
    </ng-container>
  </tbody>
</table>


    <!-- <tr *ngFor="let entry of rowData">
      <td><strong>{{ entry.EntryDate | date: 'MM/dd/yyyy' }}</strong>
      </td>
      <td>
        <div>
          <div class="d-flex w-100 justify-content-between">
            <h6 class="mb-1">{{ entry.ImplementationEstimate?.SFImplementationName ??
              entry.InternalTimeSubcategory?.Name }}</h6>
          </div>
          <p>{{ entry.Title }}</p>
          <p><small>{{ entry.Note }}</small></p>
        </div>
      </td>
      <td>{{ entry.Role?.Name ?? '--' }}</td>
      <td>{{ entry.Deliverable?.Name ?? '--' }}</td>
      <td>{{ entry.TimeSpent }}</td>
      <td>
        <div class="d-flex justify-content-center">
          <button type="button" class="btn btn-sm btn-secondary m-2" (click)="openTimeEntryEditModal(entry)"
            [disabled]="entry.TimesheetID">
            <fa-icon *ngIf="!entry.TimesheetID" [icon]="faPen"></fa-icon>
            <fa-icon *ngIf="entry.TimesheetID" [icon]="faLock"></fa-icon>
          </button>
          <button type="button" class="btn btn-sm btn-dark m-2" (click)="copyEntry(entry)"><fa-icon
              [icon]="faCopy"></fa-icon></button>
          <button type="button" class="btn btn-sm btn-danger m-2" (click)="deleteEntry(entry)"
            [disabled]="entry.TimesheetID"><fa-icon [icon]="faTrash"></fa-icon></button>
        </div>
      </td>
    </tr> -->

