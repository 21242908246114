<div class="container-fluid">
  <div class="row">
    <h3>Editing Template: <strong>{{ template?.Template.Name }}</strong></h3>
    <div class="col-lg-6">
      <div class="card" style="margin-left:15px">
        <div class="card-body">
          <ul class="list-group list-group-flush">
            <li class="list-group-item"><b>Name:</b> <input type="text" class="form-control"
                [(ngModel)]="template?.Template.Name">
            <li class="list-group-item"><b>Product:</b>
              <select class="form-control" [(ngModel)]="template?.Template.ProductTypeID">
                <option *ngFor="let productType of template?.ProductTypes" [ngValue]="productType.ProductTypeID">{{
                  productType.Name }}</option>
              </select>
            </li>
          </ul>

        </div>
      </div>

    </div>
    <div class="col-lg-6">
      <div class="table-responsive" style="overflow-y:scroll; width: 100%; max-height: 200px;">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="template?.Template.ImplementationEstimates.length == 0">
              <td colspan="2" class="text-center"><i>No implementations are currently assigned to this template.</i>
              </td>
            </tr>
            <tr *ngFor="let implementation of template?.Template.ImplementationEstimates">
              <td>{{ implementation.ImplementationEstimateID }}</td>
              <td><a [routerLink]="['../../implementations/', implementation.ImplementationEstimateID]"
                  tooltip="Edit Overrides">{{
                  implementation.SFImplementationName }}</a>&nbsp; <a [href]="implementation.ImplementationLink"
                  target="_blank" tooltip="View in Salesforce"><fa-icon [icon]="faSalesforce"></fa-icon></a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <hr>
  <div class="row mt-3">
    <div class="col-lg-12">
      <div>
        <button class="btn btn-primary m-1" (click)="saveChanges()">Save Changes</button>
        <button class="btn btn-secondary m-1" (click)="addMonth()">Add Month</button>
      </div>

      <hr>
      <tabset>
        <tab *ngFor="let role of template?.Roles" [heading]="role.Name">
          <table class="table table-responsive table-bordered table-striped mb-1"
            style="overflow-y: scroll; overflow-x: scroll; max-width: 100%;">
            <thead style="position: sticky">
              <th>Deliverable</th>
              <th class="text-center" *ngFor="let monthColumn of template?.MonthColumns;">{{ monthColumn.MonthIndex }}
              </th>
            </thead>
            <tbody>
              <ng-container *ngFor="let x of (pivotData | filterByRole: role.RoleID)">
                <tr *ngFor="let deliverable of x.Deliverables; let i=index">
                  <td style="position: sticky">{{deliverable.Deliverable.Name}}</td>
                  <ng-container *ngFor="let month of deliverable.Months">
                    <td style="min-width:2em"><input class="form-control" type="number"
                        [(ngModel)]="month.Estimate.EstimatedHours" (ngModelChange)="editHours(month.Estimate)" /></td>
                  </ng-container>
                </tr>

              </ng-container>
            </tbody>
          </table>
        </tab>
      </tabset>
    </div>
  </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#ffeb3b" type="pacman" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
