import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';

import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

import {
  EventMessage,
  InteractionStatus,
  RedirectRequest,
  PopupRequest,
  AuthenticationResult,
  EventType,
  AccountInfo,
} from '@azure/msal-browser';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService, MsalBroadcastService } from '@azure/msal-angular';

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss'],
})
export class MenuBarComponent implements OnInit, OnDestroy {
  public isCollapsed = false;
  currentRoute: string;

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  @Input() title = 'Internal Apps';
  loginDisplay = false;
  isIframe = false;
  private readonly _destroying$ = new Subject<void>();

  env = environment;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {}

  ngOnInit(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });

    if (this.authService.instance.getAllAccounts().length === 0) {
      this.authService.instance
        .initialize()
        .then(() => {
          this.loginRedirect();
        })
        .catch((err) => {
          console.error('MSAL initialization failed', err);
        });
    }
  }

  isLoggedIn() {
    this.authService.instance.getActiveAccount() !== null;
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  isActive(route: string): boolean {
    return this.currentRoute === route;
  }
}
