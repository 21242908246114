import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-template-create-modal',
  templateUrl: './template-create-modal.component.html',
  styleUrls: ['./template-create-modal.component.scss'],
})
export class TemplateCreateModalComponent implements OnInit {
  productTypes: any;
  entity: any = { ProductTypeID: '', Name: null }

  constructor(
    public modalRef: BsModalRef,
    private modalService: BsModalService,
    private dataService: DataService,
    private router: Router
  ) {}



  ngOnInit(): void {
      this.dataService.getProductTypes().subscribe(x =>  {
        this.productTypes = x;
      })
  }

  onSubmit(): void {
    if (this.entity.ProductTypeID !== '' && this.entity.Name) {
      this.dataService.createTemplate(this.entity).subscribe(x => {
        this.modalRef.hide();
        this.router.navigate(['/template', x.TemplateID]);
      }, error => this.handleError(error));
    }


  }

  handleError(error: any) {
    alert(error);
  }
}
