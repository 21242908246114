<div class="container-fluid">
  <div class="row">
    <h3>Profile Information</h3>
  </div>
  <div class="row">
    <div class="col-6">
      <ul class="list-group">
        <li class="list-group-item"><strong>First Name: </strong> {{profile?.account.name}}</li>
        <li class="list-group-item"><strong>Email: </strong> {{profile?.account.username}}</li>
        <li class="list-group-item"><strong>Object ID: </strong> {{profile?.idTokenClaims.oid}}</li>
      </ul>
    </div>
    <div class="col-6"></div>
  </div>
</div>
