import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  HyperlinkEvent,
  MatColumn,
  MaterialGridComponent,
} from 'src/app/grid/material-grid/material-grid.component';
import { CalendarEventService } from 'src/app/services/calendar-event.service';
import { DataService } from 'src/app/services/data.service';
import { SalesforceService } from 'src/app/services/salesforce.service';
import { SfObject } from 'src/app/components/sf-typeahead/sf-typeahead.component';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-calendar-event-assigment',
  templateUrl: './calendar-event-assigment.component.html',
  styleUrls: ['./calendar-event-assigment.component.scss'],
})
export class CalendarEventAssigmentComponent {
  calendarEvents: any;
  gridLoading: boolean = false;
  @ViewChild('previewModalTemplate') previewModalTemplate: any;
  @ViewChild('grid') grid: MaterialGridComponent;

  modalRef?: BsModalRef;
  modalData: {
    previewModalHtml: SafeHtml;
    previewModalSubject: string;
    selectedImplementation: SfObject | null;
    selectedCalendarEvent: any;
    selectedOpportunity: SfObject | null;
  } = {
    previewModalHtml: '',
    previewModalSubject: '',
    selectedImplementation: null,
    selectedCalendarEvent: null,
    selectedOpportunity: null,
  };

  faRefresh = faRefresh;
  assignmentForm: FormGroup;
  calendarGridConfig: MatColumn[] = [];

  constructor(
    private dataService: DataService,
    private calendarService: CalendarEventService,
    private route: ActivatedRoute,
    private router: Router,
    private salesforceService: SalesforceService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private utilityService: UtilityService
  ) {
    this.assignmentForm = this.fb.group({
      implementation: [null, Validators.required],
      opportunity: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    //this.spinnerService.show();
    this.loadCalendarEvents();
  }

  loadCalendarEvents() {
    this.gridLoading = true;
    this.calendarService.getUnassignedEvents().subscribe(
      (result) => {
        this.calendarEvents = result;
        this.calendarGridConfig = this.utilityService.getGridColumns(
          this.calendarEvents.GridConfig.GridColumns
        );
        this.gridLoading = false;
      },
      (err) => {
        alert(err);
      }
    );
  }

  refresh() {
    this.spinnerService.show();
    this.calendarService.refreshEvents().subscribe(
      (result) => {
        this.calendarEvents = result;
        this.spinnerService.hide();
      },
      (err) => alert(err)
    );
  }

  handleSave($event) {}

  handleDelete($event) {}

  handleHyperlinkClicked(event: { column: MatColumn; rowData: any }) {
    if (event.column.key == 'BodyPreview') {
      this.openPreviewModal(event.rowData);
    }
    // Any additional handling logic
  }

  openPreviewModal(element: any) {
    const content = JSON.parse(element.CalendarJson).Body?.Content;
    this.modalData.selectedCalendarEvent = element;

    this.assignmentForm.get('implementation').clearValidators();
    this.assignmentForm.get('implementation').updateValueAndValidity();

    this.assignmentForm.get('opportunity').clearValidators();
    this.assignmentForm.get('opportunity').updateValueAndValidity();

    if (element.CalendarEventType.CalendarEventTypeName == 'Demo Requests') {
      // To enable validation on the 'opportunity' control
      this.assignmentForm.get('opportunity').setValidators(Validators.required);
      this.assignmentForm.get('opportunity').updateValueAndValidity();
    }

    if (element.CalendarEventType.CalendarEventTypeName == 'CST Meetings') {
      // To enable validation on the 'opportunity' control
      this.assignmentForm
        .get('implementation')
        .setValidators(Validators.required);
      this.assignmentForm.get('implementation').updateValueAndValidity();
    }

    this.modalData.previewModalSubject = element.Subject;
    this.modalData.previewModalHtml =
      this.sanitizer.bypassSecurityTrustHtml(content);
    this.modalRef = this.modalService.show(this.previewModalTemplate, {
      class: 'modal-lg',
    });
  }

  onImplementationSelected(implementation: SfObject): void {
    this.modalData.selectedImplementation = implementation;
    this.assignmentForm.patchValue({ implementation: implementation });
  }

  onOpportunitySelected(opportunity: SfObject): void {
    this.modalData.selectedOpportunity = opportunity;
    this.assignmentForm.patchValue({ opportunity: opportunity });
  }

  assignImplementation() {
    if (this.assignmentForm.invalid) {
      return;
    }

    const implementation = this.calendarEvents?.Implementations.find(
      (x: SfObject) => x.id == this.modalData.selectedImplementation?.id
    );

    const assignment = {
      SfImplementationID: implementation.id,
      SfOpportunityID: implementation.opportunity__c,
      CalendarEventID: this.modalData.selectedCalendarEvent?.CalendarEventID,
    };

    this.spinnerService.show();
    this.modalRef.hide();

    this.calendarService.assignEvents(assignment).subscribe((result) => {
      this.calendarEvents = result;
      this.spinnerService.hide();
      this.toastr.success(
        `Calendar event ${this.modalData.selectedCalendarEvent?.Subject} saved`
      );

      this.modalData.selectedCalendarEvent = null;
      this.modalData.selectedImplementation = null;

      this.modalRef = null;
      this.assignmentForm.reset();
    });
  }
}

export interface CalendarEventAssignmentDTO {
  SfOpportunityID: string;
  SfImplementationID: string;
  CalendarEventID: number;
}
