import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  RequiredValidator,
  Validators,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/data.service';
import { StorageService } from 'src/app/services/storage.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject, take, takeUntil } from 'rxjs';
import { AzureadService } from 'src/app/services/azuread.service';
import {
  faCopy,
  faLock,
  faPen,
  faTrash,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { TimeEntryEditModalComponent } from '../time-entry-edit-modal/time-entry-edit-modal.component';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

interface TimeEntryModalInitialState {
  timeEntry: any;
}

@Component({
  selector: 'app-time-entry-screen',
  templateUrl: './time-entry-screen.component.html',
  styleUrls: ['./time-entry-screen.component.scss'],
})
export class TimeEntryScreenComponent implements OnInit {
  bsModalRef?: BsModalRef;

  sfLink: any = null;
  entity: any;
  implementation: any;
  //budgetedHours: any;
  //currentMonthInt: any;
  angForm: FormGroup;
  profile: any;
  isInternalTime: boolean = false;
  filteredSubcateogries: any[] = [];
  config: any;

  faCopy = faCopy;
  faPen = faPen;
  faTrash = faTrash;
  faLock = faLock;
  faTriangleExclamation = faTriangleExclamation;

  showTimesheetReminder: boolean = false;
  showBudgetReminder: boolean = false;

  private destroy$ = new Subject<void>();

  @ViewChild('tabs', { static: false }) tabs?: TabsetComponent;

  constructor(
    private dataService: DataService,
    private storageService: StorageService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private azuread: AzureadService,
    private modalService: BsModalService
  ) {
    this.createForm();
  }

  createForm() {
    let dt = new Date(new Date().toLocaleDateString('en-US'));

    this.angForm = this.formBuilder.group({
      implementationName: new FormControl('', Validators.required),
      implementationDeliverable: new FormControl('', Validators.required),
      implementationRole: new FormControl(
        '',
        this.isInternalTime ? Validators.required : null
      ),
      entryDate: new FormControl(dt, Validators.required),
      title: new FormControl('', Validators.required),
      timeSpent: new FormControl('', [
        Validators.required,
        this.numberValidator,
      ]),
      note: new FormControl(null),
      createAnother: new FormControl(false),
      isInternalTime: new FormControl(this.isInternalTime),
      internalTimeCategory: new FormControl({ value: '', disabled: true }),
      internalTimeSubcategory: new FormControl({ value: '', disabled: true }),
    });
  }

  ngOnInit(): void {
    this.azuread.getProfile().subscribe((profile) => (this.profile = profile));

    this.showTimesheetReminder = this.isLastDayOfMonth();

    this.angForm.get('implementationName').valueChanges.subscribe((value) => {
      //console.log('implementationName changed', value);
      this.onImplementationChanged(value);

    });

    this.angForm.get('implementationRole').valueChanges.subscribe((value) => {
      // console.log('RoleID', value);

      if (value > 0) {
        //hack to prevent clear on disable
        //this.setRoleID(value);
      }
    });

    this.angForm.get('internalTimeCategory').valueChanges.subscribe((value) => {
      //hacks for ngValue acting goofy with objects.
      let subCategories = this.config?.InternalTimeCategories.find(
        (x) => x.InternalTimeCategoryID == value
      );
      this.filteredSubcateogries = subCategories?.InternalTimeSubcategories;
    });

    this.angForm.get('isInternalTime').valueChanges.subscribe((value) => {
      this.isInternalTime = value;
      this.setFormValidators(value);
    });

    this.load();
  }

  onImplementationChanged(value: any) {
    let selectedImplementation = this.config?.ImplementationEstimates.find(
      (x) => x.ImplementationEstimateID == value
    );
    this.implementation = selectedImplementation;

    if (this.implementation) {
      const ratio = (this.implementation.HoursSpent ? this.implementation.HoursSpent : null) / this.implementation.TotalHours;
    

      if (ratio >= .75)
        this.showBudgetReminder = true;

    }

   
  }

  setFormValidators(isInternalTime: boolean) {
    if (isInternalTime) {
      this.angForm.clearValidators();

      this.clearAndSet('implementationName');
      this.clearAndSet('implementationDeliverable');
      this.clearAndSet('implementationRole');

      this.angForm
        .get('internalTimeCategory')
        .setValidators([Validators.required]);
      this.angForm.get('internalTimeCategory').setValue('');
      this.angForm.get('internalTimeCategory').enable();

      this.angForm
        .get('internalTimeSubcategory')
        .setValidators([Validators.required]);
      this.angForm.get('internalTimeCategory').setValue('');
      this.angForm.get('internalTimeSubcategory').enable();
    } else {
      this.angForm.clearValidators();
      this.angForm.get('implementationName').setValidators(Validators.required);
      this.angForm.get('implementationName').enable();

      this.angForm
        .get('implementationDeliverable')
        .setValidators(Validators.required);
      this.angForm.get('implementationDeliverable').enable();

      this.angForm.get('implementationRole').setValidators(Validators.required);
      this.angForm.get('implementationRole').enable();

      this.clearAndSet('internalTimeCategory');
      this.clearAndSet('internalTimeSubcategory');
    }
  }

  clearAndSet(fieldName: string) {
    this.angForm.get(fieldName).clearValidators();
    this.angForm.get(fieldName).setValue('');
    this.angForm.get(fieldName).disable();
  }

  isLastDayOfMonth(): boolean {
    const dt = new Date();
    const nextDay = new Date(dt);
    nextDay.setDate(dt.getDate() + 1);

    return nextDay.getMonth() !== dt.getMonth();
  }

  load(): void {
    this.spinnerService.show();

    this.dataService
      .getTimeEntryCreate()
      .pipe(take(1))
      .subscribe(
        (result) => {
          this.entity = result.TimeEntry;
          this.config = result.Config;

          this.showTimesheetReminder = this.evalTimesheetWarning();

          //hack to fix dates as strings.
          // const roleID = this.storageService.getRoleID();
          // if (roleID) {
          //   this.setRoleID(roleID);
          // }

          // this.angForm.patchValue({
          //   implementationRole: this.entity.RoleID
          // });

          this.spinnerService.hide();
        },
        (err) => this.handleError(err)
      );
  }

  evalTimesheetWarning() {
    if (!this.isLastDayOfMonth()) return false; //comment this line for testing/make visible

    if (!this.config.LastTimesheet) return true;

    const currentMonthEnd = this.lastDayOfMonth(); // Set to the last day of the current month

    const monthEnding = new Date(this.config.LastTimesheet?.MonthEnding);

    if (monthEnding.getTime() === currentMonthEnd.getTime()) {
      // LastTimesheet is for the current month
      if (this.config.LastTimesheet?.DateSubmitted) {
        console.log('LastTimesheet for this month has been submitted.');
        return false;
      } else {
        console.log('LastTimesheet for this month has not been submitted.');
        return true;
      }
    } else {
      console.log('LastTimesheet is not for the current month.');
      return true;
    }

   
  }

  lastDayOfMonth() {
    const currentMonthEnd = new Date();
    currentMonthEnd.setMonth(currentMonthEnd.getMonth() + 1, 0); // Set to the last day of the current month
    return currentMonthEnd;
  }

  isInvalid(formControlName: string) {
    return (
      this.angForm.get(formControlName).invalid &&
      this.angForm.get(formControlName).touched
    );
  }

  markTouched() {
    Object.keys(this.angForm.controls).forEach((field) => {
      const control = this.angForm.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  markAsUntouched() {
    Object.keys(this.angForm.controls).forEach((field) => {
      const control = this.angForm.get(field);
      if (control instanceof FormControl) {
        control.markAsUntouched({ onlySelf: true });
      }
    });
  }

  copyEntry(entry: any) {
    // this.angForm.reset();

    this.entity = entry;

    //console.log('est', entry.ImplementationEstimate)

    this.angForm.patchValue({
      implementationDeliverable: entry.DeliverableID ?? '',
      implementationRole: entry.RoleID ?? '',
      entryDate: new Date(entry.EntryDate),
      title: entry.Title,
      note: entry.Note,
      timeSpent: entry.TimeSpent,
      createAnother: entry.createAnother,
      isInternalTime: entry.IsInternalTime,
      internalTimeCategory:
        entry?.InternalTimeSubcategory?.InternalTimeCategory
          .InternalTimeCategoryID ?? '',
    });

    this.angForm.patchValue({
      internalTimeSubcategory: entry?.InternalTimeSubcategoryID ?? '',
    });

    this.angForm.patchValue({
      implementationName: entry?.ImplementationEstimateID ?? '',
      SFImplementationID: entry?.SFImplementationID ?? '',
    });

    //console.log(this.angForm.controls);
  }

  onSubmit(): void {
    if (!this.angForm.valid) {
      console.error('errors', this.angForm);

      this.markTouched();
      return;
    }

    //console.log(this.implementation);

    if (this.implementation && !this.implementation.ConfirmMessage) {
      this.doSubmit();
    }

    if (this.implementation === undefined) {
      this.doSubmit();
    } else if (this.implementation?.ConfirmMessage) {
      const initialState = {
        message: this.implementation.ConfirmMessage,
      };

      this.bsModalRef = this.modalService.show(ConfirmModalComponent, {
        initialState: initialState,
      });

      this.bsModalRef.content.confirmationResult
        //.pipe(takeUntil(this.destroy$))
        .subscribe((result) => {
          console.log(result);
          if (result) {
            this.doSubmit();
          } else {
            console.log('declined');
            return;
          }
        });
    }
  }

  doSubmit(): void {
    const formValues = this.angForm.value;

    let timeEntry = {
      ImplementationEstimateID: !this.isInternalTime
        ? formValues.implementationName
        : null,
      EntryDate: moment(formValues.entryDate).format('YYYY-MM-DD'),
      SFImplementationID: !this.isInternalTime
        ? this.implementation.SFImplementationID
        : null,
      RoleID: !this.isInternalTime ? formValues.implementationRole : null,
      DeliverableID: !this.isInternalTime
        ? formValues.implementationDeliverable
        : null,
      UserID: this.profile?.account.username,
      Title: formValues.title,
      Note: formValues.note,
      TimeSpent: formValues.timeSpent,
      CreateAnother: formValues.createAnother ?? false,
      InternalTimeSubcategoryID: this.isInternalTime
        ? formValues.internalTimeSubcategory
        : null,
    };

    //console.log('Posting', timeEntry);

    this.spinnerService.show();

    this.dataService.createTimeEntry(timeEntry).subscribe(
      (result) => {
        if (timeEntry.CreateAnother) {
          this.config.PriorEntries.unshift(result);
          this.markAsUntouched();
        } else {
          this.angForm.reset();

          //const roleID = this.storageService.getRoleID();

          this.angForm.patchValue({
            implementationRole: result.RoleID ?? '',
            implementationDeliverable: '',
            implementationName: '',
            internalTimeCategory: '',
            internalTimeSubcategory: '',
            entryDate: new Date(new Date().toLocaleDateString('en-US')),
          });

          this.implementation = null;
          this.filteredSubcateogries = null;
          this.load();
        }

        this.spinnerService.hide();
        this.toastr.success(timeEntry.Title, 'Time Entry Saved', {
          timeOut: 7000,
        });
      },
      (err) => this.handleError(err)
    );
  }

  handleError(err: HttpErrorResponse) {
    alert(err.message);
    this.spinnerService.hide();
  }

  setRoleID($event): void {
    //this.storageService.setRoleID(+$event);
  }

  deleteEntry($event): void {
    this.spinnerService.show();

    this.dataService.deleteTimeEntry($event.TimeEntryID).subscribe(
      (result) => {
        this.config.PriorEntries = result.PriorEntries;

        this.spinnerService.hide();
        this.toastr.success('Entry deleted.');
      },
      (err) => this.handleError(err)
    );
  }

  onDateChange(newDate: Date) {
    if (this.entity) this.entity.TimeEntry.EntryDate = new Date(newDate);
  }

  numberValidator(control: FormControl) {
    if (isNaN(control?.value)) {
      return {
        number: true,
      };
    }
    return null;
  }

  logForm() {
    console.log(this.angForm.controls);
  }

  openTimeEntryEditModal(selectedEntry: any) {
    const initialState: TimeEntryModalInitialState = {
      timeEntry: selectedEntry,
    };

    const modalConfig: ModalOptions = {
      initialState: initialState as any,
      class: 'modal-lg',
    };

    this.bsModalRef = this.modalService.show(
      TimeEntryEditModalComponent,
      modalConfig
    );

    this.bsModalRef.content.onClose.subscribe(() => {
      this.load();
    });
  }

  preferencesSaved() {
    //fetch fresh prefs
    this.load();

    this.tabs.tabs[0].active = true;
  }
}
