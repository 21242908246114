import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { observableToBeFn } from 'rxjs/internal/testing/TestScheduler';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService {
  constructor(protected http: HttpService) {}

  uploadAttachment(formData: FormData): Observable<any> {
    // for (const key in attachment) {
    //     if (attachment.hasOwnProperty(key)) {
    //         const value = attachment[key as keyof Attachment];
    //         formData.append(key, value !== null ? value.toString() : '');
    //     }
    // }
    return this.http.post(`{api}attachments/upload`, formData, { headers: new HttpHeaders() });
  }

  downloadAttachment(attachmentID: number): Observable<any> {
    return this.http.get(`{api}attachments/download/${attachmentID}`);
  }

  deleteAttachment(attachmentID: number) {
    return this.http.delete(`{api}attachments/delete/${attachmentID}`)
  }

  getList(id: number, associationType: string) {
    return this.http.get(`{api}attachments/list/${associationType}/${id}`);
  }

  getAttachmentTypes(): Observable<any[]> {
    return this.http.get(`{api}attachments/attachment-types`);
  }
}

export interface AttachmentModel {
    FileSize: number;
    OriginalFileName: string,
    AttachmentTypeID: number,
    ImplementationEstimateID: number,
    NoteID: number | null,
    CalendarEventID: number | null
    Description: string | null
}

export class Attachment implements AttachmentModel {
  
 
  constructor(init?: Partial<Attachment>) {
    Object.assign(this, init);
  }
  FileSize: number;
  OriginalFileName: string;
  AttachmentTypeID: number;
  ImplementationEstimateID: number;
  NoteID: number | null;
  CalendarEventID: number | null;
  Description: string | null
}