import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs';
import { AzureadService } from 'src/app/services/azuread.service';
import { DataService } from 'src/app/services/data.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-time-entry-edit-modal',
  templateUrl: './time-entry-edit-modal.component.html',
  styleUrls: ['./time-entry-edit-modal.component.scss']
})
export class TimeEntryEditModalComponent {
  // sfLink: any = null;
  entity: any;
  implementation: any;
  //budgetedHours: any;
  //currentMonthInt: any;
  angForm: FormGroup;
  profile: any;
  isInternalTime: boolean = false;
  filteredSubcateogries: any[] =[];
  config: any;

  timeEntry: any;

  faCopy = faCopy;

  @Output() onClose: EventEmitter<any> = new EventEmitter();

  constructor(
    private dataService: DataService,
    private storageService: StorageService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private azuread: AzureadService,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private bsDatepickerConfig: BsDatepickerConfig
  ) {
    //this.localeService.use('en-gb'); // Use the locale (adjust as needed)

    // Set the format to 'YYYY-MM-DD' and enable UTC mode
    this.bsDatepickerConfig.dateInputFormat = 'YYYY-MM-DD';
    this.bsDatepickerConfig.useUtc = true;

    this.createForm();
  }

  createForm() {
    let dt = new Date(new Date().toLocaleDateString("en-US"));

    this.angForm = this.formBuilder.group({
      implementationName: new FormControl('', Validators.required),
      implementationDeliverable: new FormControl('', Validators.required),
      implementationRole: new FormControl('', this.isInternalTime ? Validators.required : null),
      entryDate: new FormControl(dt, Validators.required),
      title: new FormControl('', Validators.required),
      timeSpent: new FormControl('',[Validators.required, this.numberValidator]),
      note: new FormControl(null),
      createAnother: new FormControl(false),
      isInternalTime: new FormControl(this.isInternalTime),
      internalTimeCategory: new FormControl({value: '', disabled: true }),
      internalTimeSubcategory: new FormControl({value: '', disabled: true})

   });
  }

  ngOnInit(): void {

    this.azuread.getProfile().subscribe(profile => this.profile = profile);

    this.angForm.get('implementationName').valueChanges.subscribe((value) => {
      //console.log('implementationName changed', value);

      let selectedImplementation = this.config?.ImplementationEstimates.find(x => x.ImplementationEstimateID == value);
      this.implementation = selectedImplementation;
    });

    this.angForm.get('implementationRole').valueChanges.subscribe((value) => {
      //console.log('RoleID', value);

      if (value > 0) { //hack to prevent clear on disable
        //this.setRoleID(value);
      }
    });

    this.angForm.get('internalTimeCategory').valueChanges.subscribe((value) => {
      //hacks for ngValue acting goofy with objects.
      let subCategories = this.config?.InternalTimeCategories.find(x => x.InternalTimeCategoryID == value);
      this.filteredSubcateogries = subCategories?.InternalTimeSubcategories;
    });

    this.angForm.get('isInternalTime').valueChanges.subscribe((value) => {
      this.isInternalTime = value;
      this.setFormValidators(value);
    });

    this.load();
  }

  load(): void {
    this.spinnerService.show();
    //console.log(this.timeEntry);

    // this.dataService.getTimeEntryCreate().pipe(take(1)).subscribe(result => {
    //   //this.entity = this.timeEntry;
    //   this.config = result.Config;

      //console.log(result);
      this.copyEntry(this.timeEntry);
      //hack to fix dates as strings.
      // const roleID = this.storageService.getRoleID();
      // if (roleID) {
      //   this.setRoleID(roleID);
      // }

      // this.angForm.patchValue({
      //   implementationRole: result.RoleID,
      // });

      this.spinnerService.hide();

    // }, err => this.handleError(err));



  }

  isInvalid(formControlName: string) {
    return this.angForm.get(formControlName).invalid && this.angForm.get(formControlName).touched;
  }

  setFormValidators(isInternalTime: boolean) {
    if (isInternalTime) {
      this.angForm.clearValidators();

      this.clearAndSet('implementationName');
      this.clearAndSet('implementationDeliverable');
      this.clearAndSet('implementationRole');

      this.angForm.get('internalTimeCategory').setValidators([Validators.required]);
      this.angForm.get('internalTimeCategory').setValue('');
      this.angForm.get('internalTimeCategory').enable();

      this.angForm.get('internalTimeSubcategory').setValidators([Validators.required]);
      this.angForm.get('internalTimeCategory').setValue('');
      this.angForm.get('internalTimeSubcategory').enable();

    } else {
      this.angForm.clearValidators();
      this.angForm.get('implementationName').setValidators(Validators.required);
      this.angForm.get('implementationName').enable();


      this.angForm.get('implementationDeliverable').setValidators(Validators.required);
      this.angForm.get('implementationDeliverable').enable();

      this.angForm.get('implementationRole').setValidators(Validators.required);
      this.angForm.get('implementationRole').enable();


      this.clearAndSet('internalTimeCategory');
      this.clearAndSet('internalTimeSubcategory');
    }
  }

  clearAndSet(fieldName: string) {
    this.angForm.get(fieldName).clearValidators();
    this.angForm.get(fieldName).setValue('');
    this.angForm.get(fieldName).disable();
  }

  markTouched() {
    Object.keys(this.angForm.controls).forEach((field) => {
      const control = this.angForm.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  markAsUntouched() {
    Object.keys(this.angForm.controls).forEach((field) => {
      const control = this.angForm.get(field);
      if (control instanceof FormControl) {
        control.markAsUntouched({ onlySelf: true });
      }
    });
  }

  copyEntry(entry: any) {
    console.log(entry);

    this.dataService.getTimeEntryCreate().pipe(take(1)).subscribe(result => {
      this.entity = this.timeEntry;
      this.config = result.Config;


      this.angForm.patchValue({
        implementationDeliverable: entry.DeliverableID ?? '',
        implementationRole: entry.RoleID ?? '',
        entryDate: new Date(entry.EntryDate),
        //entryDate: new Date(entryDate),
        title: entry.Title,
        note: entry.Note,
        timeSpent: entry.TimeSpent,
        createAnother: entry.createAnother,
        isInternalTime: entry.IsInternalTime,
        internalTimeCategory: entry?.InternalTimeSubcategory?.InternalTimeCategory?.InternalTimeCategoryID ?? '',
      });

      //console.log(this.angForm.controls['implementationRole'].value);

      this.angForm.patchValue({
        internalTimeSubcategory: entry?.InternalTimeSubcategoryID ?? ''
      });

      this.angForm.patchValue({
        implementationName: entry?.ImplementationEstimateID ?? '',
        SFImplementationID: entry?.SFImplementationID ?? ''
      });

      this.angForm.patchValue({
        implementationRole: entry?.RoleID
      });

    });
  }


  onSubmit(): void {
    if (!this.angForm.valid) {
      console.error('errors',this.angForm);

      this.markTouched();
      return;
    }


    const formValues = this.angForm.value;

    let entryToPost = {
      ImplementationEstimateID: !this.isInternalTime ? formValues.implementationName : null,
      EntryDate: moment(formValues.entryDate).format("YYYY-MM-DD"),
      SFImplementationID: !this.isInternalTime ? this.implementation.SFImplementationID : null,
      RoleID: !this.isInternalTime ? formValues.implementationRole : null,
      DeliverableID: !this.isInternalTime ? formValues.implementationDeliverable : null,
      UserID: this.profile?.account.username,
      Title: formValues.title,
      Note: formValues.note,
      TimeSpent: formValues.timeSpent,
      CreateAnother: formValues.createAnother ?? false,
      InternalTimeSubcategoryID: this.isInternalTime ? formValues.internalTimeSubcategory : null,
      TimeEntryID: this.entity.TimeEntryID
    }

    //console.log('Posting', timeEntry);

    this.spinnerService.show();

    this.dataService.createTimeEntry(entryToPost).subscribe(result => {

      if (entryToPost.CreateAnother) {
        this.config.PriorEntries.unshift(result);
        this.markAsUntouched();
      } else {
        this.angForm.reset();

        //const roleID = this.storageService.getRoleID();

        this.angForm.patchValue({
          implementationRole: '',
          implementationDeliverable: '',
          implementationName: '',
          internalTimeCategory: '',
          internalTimeSubcategory: '',
          entryDate: new Date(new Date().toLocaleDateString("en-US"))
        });


        this.implementation = null;
        this.filteredSubcateogries = null;
        this.load();
      }

      this.spinnerService.hide();
      this.toastr.success(entryToPost.Title, 'Time Entry Saved', { timeOut: 7000 });
      this.onClose.emit();
      this.bsModalRef.hide();

    }, err => this.handleError(err));
  }



  handleError(err: HttpErrorResponse) {
    alert(err.message);
    this.spinnerService.hide();
  }

  setRoleID($event): void {
    //this.storageService.setRoleID(+$event);
  }

  onDateChange(newDate: Date) {
    if (this.entity)
      this.entity.TimeEntry.EntryDate = new Date(newDate);
  }

  numberValidator(control: FormControl) {
    if (isNaN(control?.value)) {
      return {
        number: true
      }
    }
    return null;
  }

  // logForm() {
  //   console.log(this.angForm.controls);
  // }
}
