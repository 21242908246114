<div class="modal-header">
  <h4 class="modal-title pull-left">Create Template</h4>

</div>
<div class="modal-body">
  <div class="row">
    <div class="form-group">
      <label for="templateName">Template Name:</label>
      <input name="templateName" type="text" class="form-control" placeholder="Enter the template name..." [(ngModel)]="entity.Name" required/>
    </div>

  </div>
  <div class="row">
    <div class="form-group">
      <label for="product">Product:</label>
       <select name="productType" class="form-control" [(ngModel)]="entity.ProductTypeID">
        <option value="" disabled selected>Select a product type...</option>
        <option *ngFor="let productType of productTypes" [ngValue]="productType.ProductTypeID">{{ productType.Name }}</option>
       </select>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary pull-right" (click)="onSubmit()">Submit</button>
  <button type="button" class="btn btn-secondary pull-right" aria-label="Close" (click)="modalRef?.hide()">
    Close
  </button>
</div>
