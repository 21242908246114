<p-table [value]="data" [paginator]="true" [rows]="10" [sortMode]="'multiple'" [loading]="isLoading"
  styleClass="p-datatable-striped p-datatable-lg">
  <!-- Table Headers -->
  <ng-template pTemplate="header">
    <tr>
      <th *ngFor="let column of columns" pSortableColumn="{{ column.key }}">
        {{ column.header }}
        <p-sortIcon field="{{ column.key }}"></p-sortIcon>
          <!-- Filter Input Field -->
          <p-columnFilter
          field="{{ column.key }}"
          matchMode="contains"
          *ngIf="column.dataType !== 'DateTime' && isFilterable"
          type="text" display="menu"
          placeholder="Search {{ column.header }}"
        ></p-columnFilter>

        <!-- Date Filter for DateTime Columns -->
        <p-columnFilter
          field="{{ column.key }}"
          type="date"
          display="menu"
          *ngIf="column.dataType === 'DateTime' && isFilterable"
          placeholder="Filter by Date"
        ></p-columnFilter>
      </th>

      <!-- Save Button Column Header -->
      <th *ngIf="showSaveButton || showDeleteButton || showEditButton || showModalAction"> Actions </th>

    </tr>
  </ng-template>

  <!-- Table Body -->
  <ng-template pTemplate="body" let-rowData>
    <tr>
      <td *ngFor="let column of columns">
        <ng-container *ngIf="column.isHtml; else plainText">
          <span [innerHTML]="rowData[column.key]"></span>
        </ng-container>
        <ng-template #plainText>
          <ng-container [ngSwitch]="column.dataType">
            <!-- DateTime type with optional hyperlink -->
            <ng-container *ngSwitchCase="'DateTime'">
              <a *ngIf="column.isHyperlink; else plainDate" (click)="onHyperlinkClick(column, rowData, $event)">
                {{ rowData[column.key] | date: 'short' }}
              </a>
              <ng-template #plainDate>{{ rowData[column.key] | date: dateFormat }}</ng-template>
            </ng-container>

            <!-- Number type with optional hyperlink -->
            <ng-container *ngSwitchCase="'Number'">
              <a *ngIf="column.isHyperlink; else plainNumber" (click)="onHyperlinkClick(column, rowData, $event)">
                {{ rowData[column.key] | number: numberFormat }}
              </a>
              <ng-template #plainNumber>{{ rowData[column.key] | number: numberFormat }}</ng-template>
            </ng-container>

            <!-- Default text with hyperlink if specified -->
            <ng-container *ngSwitchDefault>
              <span *ngIf="column.isHyperlink; else plainDefault">
                <a type="button" (click)="onHyperlinkClick(column, rowData, $event)">
                  {{ rowData[column.key] }}
                </a>
              </span>
              <ng-template #plainDefault>{{ rowData[column.key] }}</ng-template>
            </ng-container>
          </ng-container>
        </ng-template>
      </td>
      <td >
        <button *ngIf="showSaveButton" type="button" class="btn btn-primary me-1" (click)="onSave(rowData)"><fa-icon [icon]="faFloppyDisk"></fa-icon></button>
        <button *ngIf="showDeleteButton" type="button" class="btn btn-danger me-1" (click)="onDelete(rowData)"><fa-icon [icon]="faTrash"></fa-icon></button>
        <button *ngIf="showModalAction" type="button" class="btn btn-primary me-1" (click)="onModalAction(rowData)">{{ modalActionText }}</button>
        <button *ngIf="showEditButton" type="button" class="btn btn-secondary me-1" (click)="onEdit(rowData)"><fa-icon [icon]="faPen"></fa-icon></button>
      </td>
    </tr>
  </ng-template>
</p-table>