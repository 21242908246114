

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class CalendarEventService {
  constructor(protected http: HttpService) {}

  getUnassignedEvents(): Observable<any> {
    return this.http.get(`{api}calendar/unassigned-events`);
  }

  getAssignedEvents(sfImplementationID: any): Observable<any> {
    return this.http.get(`{api}calendar/`+sfImplementationID);
  }

  refreshEvents(): Observable<any[]> {
    return this.http.get(`{api}calendar/refresh-events`);
  }

  saveDeliverable(deliverable: any): Observable<any[]> {
    return this.http.post(`{api}deliverables`, JSON.stringify(deliverable));
  }

  assignEvents(calendarAssignmentDto: any): Observable<any> {
    return this.http.post(`{api}calendar/assign-events`, JSON.stringify(calendarAssignmentDto));
  }
}