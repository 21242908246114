import { Injectable, NgModule } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveStart,
  Router,
  RouterModule,
  RouterStateSnapshot,
  Routes,
} from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { TemplateAssignmentListComponent } from './templates/template-assignment-list/template-assignment-list.component';
import { FailedComponent } from './failed/failed.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { TimeEntryScreenComponent } from './time-entry/time-entry-screen/time-entry-screen.component';
import { TemplateAssignmentComponent } from './templates/template-assignment/template-assignment.component';
import { ImplementationDetailComponent } from './implementations/implementation-detail/implementation-detail.component';
import { TemplateListComponent } from './templates/template-list/template-list.component';
import { TemplateDetailComponent } from './templates/template-detail/template-detail.component';
import { ReportListComponent } from './reports/report-list/report-list.component';
import { DeliverablesEditorComponent } from './admin/deliverables-editor/deliverables-editor.component';
import { CategoriesEditorComponent } from './admin/categories-editor/categories-editor.component';
import { TimesheetReviewComponent } from './time-entry/timesheet-review/timesheet-review.component';
import { UnauthorizedComponent } from './misc/unauthorized/unauthorized.component';
import { AzureadService } from './services/azuread.service';
import { SupportComponent } from './support/support/support.component';
import { ImplementationsListComponent } from './implementations/implementations-list/implementations-list.component';
import { CalendarEventAssigmentComponent } from './calendar-events/calendar-event-assigment/calendar-event-assigment.component';
import { ImplementationDetailSfComponent } from './implementations/implementation-detail-sf/implementation-detail-sf.component';
import { environment } from 'src/environments/environment';
import { ImplementationsListMatComponent } from './implementations/implementations-list-mat/implementations-list-mat.component';
import { ImplementationDetailSfV2Component } from './implementations/implementation-detail-sf-v2/implementation-detail-sf-v2.component';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard {
  constructor(private authService: AzureadService, private router: Router) {}

  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
  //   const expectedRoles = route.data.expectedRoles; // Get the expected role from route data

  //   if (!this.authService.isLoggedIn() || !this.authService.hasRole(expectedRoles)) {
  //     // If user is not logged in or doesn't have the expected role, redirect to unauthorized page or any desired route
  //     this.router.navigate(['/unauthorized']); // Redirect to an unauthorized route
  //     return false; // Deny access
  //   }

  //   return true; // Allow access
  // }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const expectedRoles = route.data.expectedRoles; // Get the expected roles from route data

    if (
      !this.authService.isLoggedIn() ||
      !this.authService.hasAnyRole(expectedRoles)
    ) {
      // If user is not logged in or doesn't have any of the expected roles, redirect to unauthorized page or any desired route
      this.router.navigate(['/unauthorized']); // Redirect to an unauthorized route
      return false; // Deny access
    }

    return true; // Allow access
  }
}

const adminRole = 'TimeTrackingAdministrator';
const projectManagerRole = 'TimeTracking.ProjectManager';
const env = environment;

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    //canActivate: [MsalGuard]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'template-assignment-list',
    component: TemplateAssignmentListComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'template-list',
    component: TemplateListComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'template/:Id',
    component: TemplateDetailComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRoles: [adminRole, projectManagerRole],
    },
  },
  // {
  //   path: 'templates/:Id',
  //   component: TemplateDetailTabViewComponent,
  //   canActivate: [MsalGuard]
  // },
  {
    path: 'templates/template-assignment/:Id',
    component: TemplateAssignmentComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRoles: [adminRole],
    },
  },
  {
    path: 'implementations/:Id',
    component: ImplementationDetailComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRoles: [adminRole, projectManagerRole],
    },
  },
  {
    path: 'implementation-detail/:Id',
    component: env.enableImplementationDetailFeature ? ImplementationDetailSfV2Component : ImplementationDetailComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRoles: [adminRole, projectManagerRole],
    },
  },
  {
    path: 'implementations-list',
    //component: ImplementationsListComponent,
    component: ImplementationsListMatComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRoles: [adminRole, projectManagerRole],
    },
  },
  {
    path: 'reports',
    component: ReportListComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'deliverables',
    component: DeliverablesEditorComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRoles: [adminRole],
    },
  },
  {
    path: 'internal-categories',
    component: CategoriesEditorComponent,
    canActivate: [MsalGuard, RoleGuard],
    data: {
      expectedRoles: [adminRole],
    },
  },
  {
    path: 'time-entry',
    component: TimeEntryScreenComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'timesheet-review',
    component: TimesheetReviewComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'login-failed',
    component: FailedComponent,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: 'support',
    component: SupportComponent,
    canActivate: [MsalGuard]
  },
];

if (env.enableImplementationDetailFeature) {
  routes.push({
    path: 'calendar-events',
    component: CalendarEventAssigmentComponent,
    canActivate: [MsalGuard],
  });
}

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
