<div class="container-fluid">
  <div class="row">
    <h3>Timesheet Review</h3>
  </div>
  <div class="form-group row align-items-center justify-content-between" *ngIf="config">
    <label class="col-1 col-form-label" for="MonthEnding">Month Ending:</label>
    <div class="col-8">
      <select class="form-control" name="MonthEnding" [(ngModel)]="config.SelectedTimesheetMonthEnd"
        (ngModelChange)="filterTimeEntriesByMonthEnd()">
        <option *ngFor="let timesheet of config?.Timesheets" [ngValue]="timesheet.MonthEnding">{{ timesheet.MonthEnding
          | date:
          'yyyy-MM-dd' }}</option>
      </select>
    </div>
    <div class="col-3">
      <!-- <button class="btn btn-secondary m-2" type="button">Create</button> -->
      <button class="btn btn-primary m-2" type="button" (click)="openTimesheetSubmitModal(submitTimesheet)"
        [disabled]="selectedTimesheet?.DateSubmitted != null">Submit Timesheet</button>
    </div>
  </div>
  <div class="row">
    <div class="align-items-middle text-center">
      <h3 *ngIf="selectedTimesheet?.DateSubmitted" class="alert alert-warning">The timesheet you are reviewing was
        submitted on {{ selectedTimesheet?.DateSubmitted | date: 'yyyy-MM-dd' }}. Entries associated with this timesheet
        are not editable.</h3>
      <div *ngIf="selectedTimesheet">Total Hours: {{ selectedTimesheet.TotalHours }} | Number of Entries: {{
        selectedTimesheet.TotalEntries }}</div>
    </div>
  </div>
  <div class="row">
    <app-grouped-entries-grid style="max-height: 80vh; overflow-y: auto;" [rowData]="timeEntries"
      (openTimeEntryEditModalEvent)="openTimeEntryModal($event)" [showEditButton]="true"
      [showDeleteButton]="false"></app-grouped-entries-grid>
  </div>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#ffeb3b" type="pacman" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
</div>

<ng-template #submitTimesheet>
  <div class="modal-header">
    <h4>Submit Timesheet</h4>
  </div>
  <div class="modal-body">
    <p>Submitting this timesheet will
      <strong>lock all time entries for {{ config.SelectedTimesheetMonthEnd | date: 'yyyy-MM-dd'}}</strong>.
      Are you sure?
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-warning" aria-label="Submit Timesheet" (click)="doSubmit()">Submit
      Timesheet</button>
    <button type="button" class="btn btn-secondary pull-right" aria-label="Close" (click)="bsModalRef?.hide()">
      Close
    </button>
  </div>
</ng-template>