<div class="container-fluid" *ngIf="sfImplementation">
  <div class="row mb-3">
    <div class="col-12 d-flex justify-content-between align-items-center">
      <h2>Implementation Detail - {{ data.ImplementationEstimate.SFImplementationName }}</h2>
      <div class="ms-auto">
        <button class="btn btn-primary me-2" (click)="openAttachmentModal()">
          <fa-icon [icon]="faUpload"></fa-icon> Add Attachment
        </button>
        <button class="btn btn-primary me-2" (click)="openNotesModal()">
          <fa-icon [icon]="faNoteSticky"></fa-icon> Add Note
        </button>
        <button type="submit" class="btn btn-primary" [disabled]="!isEdited || !implementationForm.valid"
          (click)="doSave()">
          Save
        </button>
      </div>
    </div>
  </div>

  <form [formGroup]="implementationForm">
    <div class="card mb-3">
      <div class="card-header">
        <h2><fa-icon [icon]="faInfo" class="text-primary"></fa-icon> &nbsp; Information:</h2>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="implementationName">Implementation Name</label>
              <a class="form-control" [href]="sfLink" target="__blank"><fa-icon class="text-primary"
                  [icon]="faSalesforce"></fa-icon> {{ sfImplementation.name }}
              </a>
            </div>
            <div class="form-group">
              <cst-dropdown [options]="phasePickListValues" formControlName="phase__c" label="Phase"
                [displayProperty]="'label'" [valueProperty]="'value'" name="phase__c">
              </cst-dropdown>
            </div>
            <div class="form-group">
              <label for="implementationOpportunity">Opportunity</label>
              <a class="form-control" name="implementationOpportunity"
                [href]="'https://o3solutions.lightning.force.com/lightning/r/Opportunity/'+sfImplementation.opportunity__r.id + '/view'"
                target="_blank">{{ sfImplementation.opportunity__r.name }}</a>
            </div>
            <div class="form-group">
              <label for="implementationLocation">Location</label>
              <span class="form-control" id="implementationActualHours" name="implementationActualHours">{{
                sfImplementation.location__c.length > 2 ? sfImplementation.location__c : 'Not available - See Opportunity.' }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="implementationNumber">Implementation Number</label>
              <input type="text" class="form-control" id="implementationNumber" formControlName="number__c"
                name="implementationNumber">
            </div>
            <div class="form-group">
              <label for="implementationStartDate">Implementation Start Date</label>
              <input type="text" class="form-control" placeholder="Start Date"
                [ngClass]="{ 'is-invalid': implementationForm.get('implementation_Start_Date__c').invalid && implementationForm.get('implementation_Start_Date__c').touched }"
                formControlName="implementation_Start_Date__c" bsDatepicker>

            </div>
            <div class="form-group">
              <label for="implementationEndDate">Implementation End Date</label>
              <input type="text" class="form-control" placeholder="End Date"
                [ngClass]="{ 'is-invalid': implementationForm.get('implementation_End_Date__c').invalid && implementationForm.get('implementation_End_Date__c').touched }"
                formControlName="implementation_End_Date__c" bsDatepicker>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-3">
      <div class="card-header"><fa-icon [icon]="faDollar" class="text-success"></fa-icon> &nbsp; Financial:</div>
      <div class="card-body">
        <div class="row">
          <div class="col in">
            <div class="form-group">
              <label for="implementationMrr">MRR</label>
              <input type="text" class="form-control" id="implementationMrr" formControlName="mMR__c"
                name="implementationMrr">
            </div>
            <div class="form-group">
              <label for="implementationActualHours">Actual Hours</label>
              <span class="form-control" id="implementationActualHours" name="implementationActualHours">{{
                data.ImplementationEstimate.HoursSpent }}</span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="implementationHoursSold">Hours Sold</label>
              <input type="text" class="form-control" id="implementationHoursSold" formControlName="hour_Sold__c"
                name="implementationHoursSold">
            </div>
            <div class="form-group">
              <label for="implementationHoursEstimated">Hours Estimated</label>
              <span class="form-control" id="implementationHoursEstimated" name="implementationHoursEstimated">{{
                data.ImplementationEstimate.TotalHours }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr>

    <div *ngIf="implementation" class="card">
      <div class="card-header">
        <h2><fa-icon class="text-secondary" [icon]="faFile"></fa-icon> &nbsp; Attachments:</h2>
      </div>
      <div class="card-body">
        <app-attachments-list [associationType]="'implementation'"
          [id]="implementation?.ImplementationEstimateID"></app-attachments-list>
      </div>
    </div>
    <div>
      
      <div class="card">
        <div class="card-header">
          <h2>Additional Info:</h2>
        </div>
        <div class="card-body">
          <tabset>
            <tab>
              <ng-template tabHeading>
                <span><fa-icon [icon]="faUser" class="text-primary"></fa-icon> Team Members:</span>
              </ng-template>
              <div class="card mb-3">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <cst-dropdown [options]="O3Contacts" formControlName="project_Manager_Contact__c"
                          label="Project Manager" [displayProperty]="'name'" [valueProperty]="'id'"
                          name="implementationProjectManager"></cst-dropdown>
                        <div
                          *ngIf="implementationForm.get('project_Manager_Contact__c').invalid && implementationForm.get('project_Manager_Contact__c').touched"
                          class="invalid-feedback">
                          Project Manager is required.
                        </div>
                      </div>
                      <div class="form-group">
                        <cst-dropdown [options]="O3Contacts" formControlName="o3_SME_Contact__c"
                          [displayProperty]="'name'" [valueProperty]="'id'" label="SME"
                          name="implementationSme"></cst-dropdown>
                      </div>
                      <div class="form-group">
                        <cst-dropdown [options]="O3Contacts" formControlName="data_Consultant_Contact__c"
                          label="Data Consultant" [displayProperty]="'name'" [valueProperty]="'id'"
                          name="implementationData"></cst-dropdown>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <cst-dropdown [options]="O3Contacts" formControlName="aWP_Consultant_Contact__c"
                          label="AWP Consultant" [displayProperty]="'name'" [valueProperty]="'id'"
                          name="implementationAwp"></cst-dropdown>
                      </div>
                      <div class="form-group">
                        <cst-dropdown [options]="O3Contacts" formControlName="trainer_Contact__c" label="Trainer"
                          [displayProperty]="'name'" [valueProperty]="'id'" name="implementationTrainer"></cst-dropdown>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </tab>
            <tab>
              <ng-template tabHeading>
                <span><fa-icon class="text-primary" [icon]="faCalendar"></fa-icon>&nbsp; Dates:</span>
              </ng-template>
              <div class="card mb-3">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="implementationPODate">PO Date</label>
                        <input type="text" class="form-control" placeholder="PO Date" formControlName="pO_Date__c"
                          bsDatepicker>
                      </div>
                      <div class="form-group">
                        <label for="implementationKODate">KO Date</label>
                        <input type="text" class="form-control" placeholder="KO Date" formControlName="kO_Date__c"
                          bsDatepicker>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="plannedGoLiveDate">Planned Go Live Date</label>
                        <input type="text" class="form-control" placeholder="Planned Go Live Date"
                          formControlName="planned_Go_Live_Date__c" bsDatepicker>
                      </div>
                      <div class="form-group">
                        <label for="implementationActualGoLiveDate">Actual Go Live Date</label>
                        <input type="text" class="form-control" placeholder="Actual Go Live Date"
                          formControlName="actual_Go_Live_Date__c" bsDatepicker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </tab>
          </tabset>
        </div>
      </div>

    </div>
  </form>

</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#ffeb3b" type="pacman" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>