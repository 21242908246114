import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DataService } from 'src/app/services/data.service';
import { SalesforceService } from 'src/app/services/salesforce.service';

@Component({
  selector: 'app-template-assignment',
  templateUrl: './template-assignment.component.html',
  styleUrls: ['./template-assignment.component.scss']
})
export class TemplateAssignmentComponent implements OnInit {
  modalRef?: BsModalRef;
  params: any;
  products: any;

  selectedProduct: any;
  template: any[];

  constructor(private modalService: BsModalService, private route: ActivatedRoute,
    private salesforceService: SalesforceService, private dataService: DataService) {}

  ngOnInit(): void {
      this.route.params.subscribe(params => {
        this.params = params;
      });

      this.dataService.getTemplate(this.params['Id']).subscribe(result => {
        this.template = result;
      })

      // this.salesforceService.getAllProducts().subscribe(result => {

      //   //console.log(result);

      // });
  }

  showAssignmentModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-lg'});
  }
}
