import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  roleKey: string = 'RoleID';

  setRoleID(id: any) {
    localStorage.setItem(this.roleKey, id);
  }

  getRoleID(): number {
    return +localStorage.getItem(this.roleKey);
  }
}
