import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  EventMessage,
  EventType,
  InteractionStatus,
  RedirectRequest,
} from '@azure/msal-browser';
import { Observable, Subject, catchError, filter, takeUntil, throwError } from 'rxjs';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

@Injectable({
  providedIn: 'root',
})
export class AzureadService {
  private readonly _destroying$ = new Subject<void>();
  private loginDisplaySubject = new Subject<boolean>();
  loginDisplay$ = this.loginDisplaySubject.asObservable();

  constructor(
    private http: HttpClient,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {}

  getProfile(): Observable<any> {
    const accessTokenRequest = {
      scopes: ['User.Read'],
    };

    return this.authService.acquireTokenSilent(accessTokenRequest)
      .pipe(
        catchError((error) => {
          console.error('Token acquisition failed', error);
          return throwError(() => error);
        })
      );
  }

  setLoginDisplay(): void {
    const display = this.authService.instance.getAllAccounts().length > 0;
    this.loginDisplaySubject.next(display);
  }

  checkAndSetActiveAccount(): void {
    let activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  loginRedirect(): void {
    const authRequest = this.msalGuardConfig.authRequest;
    this.authService.loginRedirect(authRequest as RedirectRequest);
  }

  logout(popup = false): void {
    if (popup) {
      this.authService.logoutPopup({ mainWindowRedirectUri: '/' });
    } else {
      this.authService.logoutRedirect();
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  isLoggedIn(): boolean {
    return this.authService.instance.getActiveAccount() !== null;
  }

  getRoles(): string[] {
    const tokenClaims =
      this.authService.instance.getActiveAccount()?.idTokenClaims;

    //console.log(tokenClaims);

    // Assuming roles are stored in a claim named 'roles'
    if (tokenClaims && tokenClaims.roles) {
      // Roles might be a string or an array in the claims, handle accordingly
      if (Array.isArray(tokenClaims.roles)) {
        return tokenClaims.roles;
      } else {
        return [tokenClaims.roles];
      }
    }

    return [];
  }

  hasRole(expectedRole: string): boolean {
    const userRoles = this.getRoles(); // Get user roles
    return userRoles.includes(expectedRole);
  }

  hasAnyRole(expectedRoles: string[]): boolean {
    const userRoles = this.getRoles();
    return userRoles.some((role) => expectedRoles.includes(role));
  }
}
