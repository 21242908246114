<div class="raid-matrix-grid row gx-4 gy-4 mt-2">
    <div class="row pt-1">
        <span class="text-center">RAID Types and Priority</span>
        <p-chart *ngIf="chartData" type="bar" [data]="chartData" [options]="chartOptions"></p-chart>
      </div>
    <!-- <div class="row">
        <div class="d-flex justify-content-end g-0">
            <p-toggleButton [(ngModel)]="showArchived" onLabel="Hide archived" offLabel="Show archived"
             (onChange)="setArchivedDisplay()">
            </p-toggleButton>
        </div>
    </div> -->
    <!-- Risks Section -->
    <div class="col-md-6">
        <app-raid-matrix-item *ngIf="risksType" [implementationEstimate]="implementationEstimate" [showArchived]="showArchived" (refresh)="refresh()"
            [noteType]="risksType"></app-raid-matrix-item>

    </div>

    <!-- Assumptions Section -->
    <div class="col-md-6">
        <app-raid-matrix-item *ngIf="assumptionType" [implementationEstimate]="implementationEstimate" [showArchived]="showArchived" (refresh)="refresh()"
            [noteType]="assumptionType"></app-raid-matrix-item>

    </div>

    <!-- Issues Section -->
    <div class="col-md-6">
        <app-raid-matrix-item *ngIf="issuesType" [implementationEstimate]="implementationEstimate" [showArchived]="showArchived" (refresh)="refresh()"
            [noteType]="issuesType"></app-raid-matrix-item>
    </div>

    <!-- Dependencies Section -->
    <div class="col-md-6">
        <app-raid-matrix-item *ngIf="dependenciesType" [implementationEstimate]="implementationEstimate" [showArchived]="showArchived" (refresh)="refresh()"
            [noteType]="dependenciesType"></app-raid-matrix-item>
    </div>

  <!-- <div class="row pt-1">
    <hr />
    <span class="text-center">RAID Chart</span>
    <p-chart *ngIf="chartData" type="bar" [data]="chartData" [options]="chartOptions"></p-chart>
  </div> -->
</div>