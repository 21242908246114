<div class="container">
  <div class="row mt-5" style="text-align:center">
    <div *ngIf="!loginDisplay">
      <p class="welcome">Welcome to the O3 Time Tracking App!</p>
      <p>Use the button at the bottom of the menu to login/out</p>
    </div>

    <div *ngIf="loginDisplay">
      <p *ngIf="profile?.account?.username"><b>Login successful!</b><br> Welcome, {{ profile?.account?.username }}</p>
    </div>
  </div>
</div>


