<div class="container-fluid">
  
  <div class="row">
    <div class="col-8">
      <h3>Deliverables</h3>
    </div>
    <div class="col-4 d-flex justify-content-end">
      <button class="btn btn-primary" (click)="showModal(template, { DeliverableID: 0, Name: '', IsBillable: false })"><fa-icon [icon]="faPlus">Add</fa-icon></button>
    </div>
  </div>


  <div class="row mt-3">
    <div class="table-responsive table-wrapper-scroll-y my-custom-scrollbar">
      <table class="table table-bordered table-striped mb-0">
        <thead>
          <th>ID</th>
          <th>Name</th>
          <th>Is Billable</th>
          <th></th>
        </thead>
        <tbody>
          <tr *ngFor="let deliverable of deliverables">
            <td>{{ deliverable.DeliverableID }}</td>
            <td>{{ deliverable.Name }}</td>
            <td><input type="checkbox" [(ngModel)]="deliverable.IsBillable" readonly disabled="disabled"></td>
            <td>
              <button type="button" class="btn btn-secondary" (click)="showModal(template, deliverable)"><fa-icon [icon]="faPen">Edit</fa-icon></button> &nbsp;
              <!-- <button type="button" class="btn btn-danger" (click)="showConfirmModal(confirm, deliverable)"><fa-icon [icon]="faTrash">Delete</fa-icon></button> -->
            </td>
          </tr>
        </tbody>
      </table>
  </div>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#ffeb3b" type="pacman" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
</div>

<ng-template #confirm>
  <div class="modal-header">
    <h4>Delete deliverable</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <p>Are you sure you want to delete {{ selectedDeliverable?.Name }}?</p>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-warning" aria-label="Confirm" (click)="doDelete(selectedDeliverable)">Confirm</button>
    <button type="button" class="btn btn-secondary pull-right" aria-label="Close" (click)="modalRef?.hide()">
      Close
    </button>
  </div>
</ng-template>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ selectedDeliverable.DeliverableID == 0 ? 'Create new deliverable' : 'Modify existing deliverable' }}</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="form-inline">
          <label for="Name">Name:</label>
          <input class="form-control" type="text" name="Name" [(ngModel)]="selectedDeliverable.Name" required>

          <label class="form-check-label-prepend" for="IsBillable">Is Billable: &nbsp;</label>
          <input class="form-check-input" type="checkbox" name="IsBillable" [(ngModel)]="selectedDeliverable.IsBillable">
      </div>

    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary pull-right" (click)="saveDeliverable(selectedDeliverable)" [disabled]="selectedDeliverable.Name.length === 0">Submit</button>
    <button type="button" class="btn btn-secondary pull-right" aria-label="Close" (click)="modalRef?.hide()">
      Close
    </button>
  </div>
</ng-template>
