import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ChartOptions } from 'chart.js';
import { NotesService } from 'src/app/services/notes.service';

@Component({
  selector: 'app-raid-matrix',
  templateUrl: './raid-matrix.component.html',
  styleUrls: ['./raid-matrix.component.scss'],
})
export class RaidMatrixComponent implements OnInit, AfterViewInit {
  @Input() implementationEstimate: any;
  raidNotes: any;
  noteTypes: any[] = [];
  showArchived: boolean = false;

  faTrash = faTrash;
  faPen = faPen;
  faPlus = faPlus;

  risksType: any;
  assumptionType: any;
  issuesType: any;
  dependenciesType: any;
  chartData: {
    labels: unknown[];
    datasets: { label: unknown; backgroundColor: string; data: any[] }[];
  };
  chartOptions: any;

  constructor(private notesService: NotesService) {}

  ngAfterViewInit(): void {
    this.loadChart();        
  }

  refresh() {
    this.loadChart();
  }

  private loadChart() {
    this.notesService
      .getRaidBarChart(this.implementationEstimate.ImplementationEstimateID)
      .subscribe((data) => {
        // Get unique NoteTypes and Priorities
        const noteTypes = [...new Set(data.map((d: any) => d.NoteType))]; // Use NoteType (from JSON)
        const priorities = [...new Set(data.map((d: any) => d.Priority))]; // Use Priority (from JSON)


        // Prepare the datasets for the chart
        const datasets = priorities.map((priority: string) => ({
          label: priority,
          backgroundColor: this.getPriorityColor(priority), // Color based on priority
          data: noteTypes.map((type) => {
            // Find the matching data for the current NoteType and Priority
            const matchingData = data.find(
              (d: any) => d.NoteType === type && d.Priority === priority
            );
            return matchingData ? matchingData.Count : 0; // If no match, return 0
          }),
        }));

        // Set chart data and options
        this.chartData = {
          labels: noteTypes, // The x-axis labels are the unique NoteTypes
          datasets: datasets, // The datasets for each priority
        };



        // Define the chart options with correct type assignment
        this.chartOptions = {
          responsive: true, // Ensures the chart is responsive to container resizing
          maintainAspectRatio: false, // Allows the chart to adjust based on the container
          plugins: {
            legend: {
              position: 'top', // Position of the legend
            },
          },
          scales: {
            x: {
              stacked: true, // Stack the bars on the x-axis
            },
            y: {
              stacked: true,
            },
          },
        };

      });
  }

  ngOnInit(): void {
    this.notesService.getNoteTypes('RAID').subscribe((x) => {
      this.noteTypes = x;

      this.risksType = this.noteTypes.find((y) => y.Name == 'Risk');
      this.assumptionType = this.noteTypes.find((y) => y.Name == 'Assumption');
      this.issuesType = this.noteTypes.find((y) => y.Name == 'Issues');

      this.dependenciesType = this.noteTypes.find(
        (y) => y.Name == 'Dependencies'
      );
    });
  }

  getPriorityColor(priority: string): string {
    switch (priority) {
      case 'High':
        return '#ff4d4f'; // Red for high priority
      case 'Medium':
        return '#ffa940'; // Orange for medium priority
      case 'Low':
        return '#52c41a'; // Green for low priority
      case 'Critical':
        return '#f5222d'; // A brighter red for critical priority
      default:
        return '#d9d9d9'; // Default gray for unknown or undefined priorities
    }
  }

  setArchivedDisplay() {
    this.showArchived = !this.showArchived;
  }
}
