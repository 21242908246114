<div class="container-fluid">
  <div class="row">
    <div class="col-3">
      <h3>Templates</h3>
    </div>
    <div class="col-9 d-flex justify-content-end">
      <button class="right" type="button" class="btn btn-primary btn-sm" tooltip="Create a new Template" (click)="showCreateModal()"><fa-icon [icon]="faPlus"></fa-icon> Create Template</button>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-12">
      <div class="table-responsive" style="max-height:800px; overflow:auto;">
        <table class="table table-bordered table-striped mb-0">
          <thead>
            <tr>
              <th scope="row">ID</th>
              <th scope="row">Product</th>
              <th scope="row">Name</th>
              <th scope="row">Total Hours</th>
              <th scope="row">Actions</th>
            </tr>

          </thead>
          <tbody>
            <tr *ngFor="let template of templates">
              <td>{{ template.TemplateID }}</td>
              <td>{{template.ProductType.Name}}</td>
              <td>{{ template.Name }} </td>
              <td>{{template.TotalHours}}</td>
              <td>
                <a class="btn btn-primary" style="margin-right:5px" type="button"  [routerLink]="['../template/'+ template.TemplateID]"> <fa-icon [icon]="faPen"></fa-icon></a>
                <button class="btn btn-danger" type="button" (click)="deleteEstimate(template)"> <fa-icon [icon]="faTrash"></fa-icon></button>
                <!-- <button class="btn btn-primary" type="button">Delete</button> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#ffeb3b" type="pacman" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>
