<div class="card">
    <div class="card-header">
      <div class="d-flex justify-content-between align-items-center w-100">
        <h5 class="mb-0">{{ noteType?.Name }}</h5>
        <button type="button" class="btn btn-primary btn-sm ms-2" (click)="addItem()">
          <fa-icon [icon]="faPlus"></fa-icon>
        </button>
      </div>
    </div>
    <div class="card-body">
      <p-dataView [value]="raidNotes" layout="grid" [paginator]="false">
        <ng-template let-item pTemplate="gridItem">
          <div class="card h-100">
            <div class="card-header d-flex align-items-center">
              <span>{{ item.Title }}</span>
              <span class="badge ms-3" [ngClass]="getPriorityClass(item.NotePriorityDescription)">
                {{ item.NotePriorityDescription }}
              </span>
              <div class="ms-auto d-flex">
              
                <button class="btn btn-success btn-sm me-2" (click)="editItem(item)">
                  <fa-icon [icon]="faPen"></fa-icon>
                </button>
                <!-- <button class="btn btn-danger btn-sm" (click)="deleteItem(item)">
                  <fa-icon [icon]="faTrash"></fa-icon>
                </button> -->
              </div>
            </div>
            <div class="card-body">
              <p [innerHTML]="item.NoteBodyHtml"></p>
            </div>
            <div class="card-footer d-flex justify-content-end align-items-center">
                <small class="me-2">Created <b>{{ item.DateCreated | date: 'MM/dd/YYYY' }}</b> by {{ item.CreatedByUserID }}</small>
                <small *ngIf="item.NotePriority == 5" class="me-2">Archived <b>{{ item.DateArchived | date: 'MM/dd/YYYY' }}</b> by {{ item.ArchivedByUserID }}</small>
                <!-- <button *ngIf="!item.DateArchived" type="button" class="btn btn-warning btn-sm" tooltip="Archive Note" (click)="archiveItem(item)">
                    <fa-icon [icon]="faBoxArchive"></fa-icon>
                </button> -->
            </div>
          </div>
        </ng-template>
      </p-dataView>
    </div>
  </div>
  