<div class="row mt-3">
  <h3></h3>
  <div class="pull-right">
    <button class="btn btn-primary" (click)="showAssignmentModal(template)">Add Assignment</button>
  </div>
</div>
<div class="row mt-3">
  <div class="table-responsive table-wrapper-scroll-y my-custom-scrollbar">
    <table class="table table-bordered table-striped mb-0">
      <thead>
        <tr>
          <th>ID</th>
          <th>Salesforce ID</th>
          <th>Product</th>
          <th>Delete</th>
        </tr>
      </thead>
    </table>
  </div>
</div>

<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add Salesforce Implementation Assignment</h4>

  </div>
  <div class="modal-body">
    <div class="row">
      <div class="form-group">
        <label for="productTypeahead">Product:</label>
        <input name="productTypeahead" [(ngModel)]="selectedProduct" [typeahead]="products" placeholder="Type to select an implementation"
          typeaheadOptionField="TypeaheadDescription" class="form-control" required="true">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary pull-right">Submit</button>
    <button type="button" class="btn btn-secondary pull-right" aria-label="Close" (click)="modalRef?.hide()">
      Close
    </button>
  </div>
</ng-template>
