<div class="container-fluid">
    <div class="row">
      <h3>Implementation List</h3>
    </div>
    <div class="row">
      <div class="col-12">
        <app-material-grid style="width: 100%;" [data]="implementations?.Data" [columns]="gridColumns" [showSaveButton]="false"
        [showDeleteButton]="false" [showModalAction]="false" [modalActionText]="'Assign'" [showDeleteButton]="true" (delete)="showDeleteAssignmentModal(deleteTemplate, $event)"
        (hyperlinkClicked)="handleHyperlinkClicked($event)" [loading]="gridLoading">
    </app-material-grid>
  </div>
  
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#ffeb3b" type="pacman" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  
  <ng-template #deleteTemplate>
    <div class="modal-body text-center">
      <p><b><fa-icon [icon]="faTriangleExclamation" class="text-danger"></fa-icon>&nbsp; Do you want archive this implementation?</b></p>   
      <p>This action will archive the implementation through soft-delete. Time Entries will still be present.</p>
      <button type="button" class="btn btn-danger m-1" (click)="deleteImplementation()">Yes</button>
      <button type="button" class="btn btn-primary m-1" (click)="modalRef?.hide()">No</button>
    </div>
  </ng-template>
  