<form [formGroup]="noteForm" (ngSubmit)="doSave()">
  <div class="modal-header">
    <h3>Add {{ NoteType ? NoteType.Name : 'Note' }} to {{ ImplementationEstimate?.SFImplementationName }} </h3>
  </div>
  <div class="modal-body">
    <div  *ngIf="showNoteType" class="row">
      <div class="col-12">
        <div class="form-group">
          <div class="form-control">
            <cst-dropdown *ngIf="noteDto?.NoteTypes" [options]="noteDto?.NoteTypes" [valueProperty]="'NoteTypeID'"
              label="Note Type:" [displayProperty]="'Name'" formControlName="NoteTypeID" (change)="onNoteTypeChanged()"
              style="max-width: 50%;">
            </cst-dropdown>
            <div *ngIf="noteForm.controls.NoteTypeID.invalid && noteForm.controls.NoteTypeID.touched">
              <small class="text-danger">Note Type is required</small>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="showPriorities" class="row">
      <div class="col-12">
        <div class="form-group">
          <div class="form-control">
            <cst-dropdown *ngIf="noteDto?.Priorities" [options]="noteDto?.Priorities" 
            [valueProperty]="'Value'" label="Priority:" [displayProperty]="'Description'"
            formControlName="Priority" (change)="onPriorityChanged()"></cst-dropdown>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="showTitle" class="row">
      <div class="col-12">
        <div class="form-group">
          <div *ngIf="noteForm.controls.Title.invalid && noteForm.controls.Title.touched">
            <small class="text-danger">Title is required.</small>
          </div>
          <label for="Title">Title:</label>
          <input type="text" class="form-control" placeholder="Enter a title..." maxlength="50" formControlName="Title" />
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-12">
        <div class="form-group">

          <div *ngIf="noteForm.controls.NoteBodyHtml.invalid && noteForm.controls.NoteBodyHtml.touched">
            <small class="text-danger">Note body is required (minimum 10 characters)</small>
          </div>
          <label for="editor">Note:</label>
          <quill-editor id="editor" class="form-control" style="width: 100%;" [modules]="modules"
            formControlName="NoteBodyHtml" (onEditorCreated)="onEditorCreated($event)" [styles]="{ height: '100px' }">
          </quill-editor>
        </div>
      </div>
    </div>

   


    <div class="row">
      <div class="col-12">
        <div class="form-group justify-content-center">
          <label>Attachments:</label>
      
          <ul class="form-control list-group" style="margin: 0; padding: 0;">
            <li class="list-group-item" *ngIf="attachments.length === 0">
              No Attachments.
            </li>
            <li class="list-group-item" *ngFor="let attachment of attachments">{{ attachment.OriginalFileName }} 
              <button type="button" (click)="deleteAttachment(attachment.AttachmentID)"
                class="btn btn-danger btn-sm"><fa-icon class="mr-2" [icon]="faTrash"></fa-icon>
              </button>
            </li>
          </ul>
          
        </div>
        <p *ngIf="!attachments">Click 'Attach File'...</p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="d-flex align-items-right">
        <button type="button" class="btn btn-secondary me-2" (click)="openAttachmentModal()">Attach File</button>
       
       <button type="submit" class="btn btn-primary me-2" [disabled]="noteForm.invalid" style="margin-right:2px;">
          Save
        </button>
        <button type="button" class="btn btn-danger" (click)="closeModal()">Close</button>
      </div>
    </div>
  </div>
  <div *ngIf="isLoading" class="modal-overlay">
    <p-progressSpinner style="margin-left: auto; margin-right: auto;" [style]="{ 'position': 'absolute', 'top': '50%', 'left': '50%', 'transform': 'translate(-50%, -50%)' }"></p-progressSpinner>
  </div>
</form>
