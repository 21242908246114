<div class="container-fluid">
    <div class="row">
        <div class="col-8">
            <h3>Calendar Event Assignment</h3>
        </div>
        <div class="col-4 d-flex justify-content-end">
            <p>Not seeing what you are looking for? &nbsp; 
                <button class="btn btn-danger" (click)="refresh()">
                    <fa-icon [icon]="faRefresh"></fa-icon>
                </button>
            </p>
        </div>
    </div>
</div>
<div class="container-fluid">
    <app-material-grid #grid style="width: 100%;"  [showSaveButton]="false" [columns]="calendarGridConfig" [data]="calendarEvents?.Data" (hyperlinkClicked)="handleHyperlinkClicked($event)"
        [showDeleteButton]="false" [showModalAction]="false" [modalActionText]="'Assign'" [isLoading]="gridLoading" [dateFormat]="'short'"
        (save)="handleSave($event)">
    </app-material-grid>
  </div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="large" color="#ffeb3b" type="pacman" [fullScreen]="false">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
<ng-template #previewModalTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ modalData.previewModalSubject }}</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div *ngIf="modalData.selectedCalendarEvent?.CalendarEventType?.CalendarEventTypeName == 'CST Meetings'" class="d-flex align-items-center mb-3">
            <label for="implementation" class="form-label me-2">Assign to:</label>
            <app-sf-typeahead
                class="form-control me-2"
                name="implementation"
                [dataSource]="calendarEvents.Implementations"
                [(selectedObject)]="modalData.selectedImplementation"
                (objectSelected)="onImplementationSelected($event)">
            </app-sf-typeahead>
            <button type="button" class="btn btn-primary" [disabled]="assignmentForm.invalid" (click)="assignImplementation()">Save</button>
        </div>
        <div *ngIf="modalData.selectedCalendarEvent?.CalendarEventType?.CalendarEventTypeName == 'Demo Requests'" class="d-flex align-items-center mb-3">
            <label for="opportunity" class="form-label me-2">Assign to:</label>
            <app-sf-typeahead
                class="form-control me-2"
                name="opportunity"
                [dataSource]="calendarEvents.Opportunities"
                [(selectedObject)]="modalData.selectedOpportunity"
                (objectSelected)="onImplementationSelected($event)">
            </app-sf-typeahead>
            <button type="button" class="btn btn-primary" [disabled]="assignmentForm.invalid" (click)="assignImplementation()">Save</button>
        </div>
        <hr>
        <div>
            <p [innerHTML]="modalData.previewModalHtml"></p>
        </div>
    </div>
</ng-template>

