import { Component, EventEmitter, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {
  @Input() message: string; // Input property to pass the message
  confirmationResult: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public bsModalRef: BsModalRef) {}

  onCancel() {
    this.confirmationResult.emit(false);
    this.bsModalRef.hide();
  }

  onConfirm() {
    // Handle confirmation logic here
    this.confirmationResult.emit(true);
    this.bsModalRef.hide();
  }
}
