<div class="container-fluid">
  <div class="row">
    <h3>Implementation List</h3>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="table-responsive table-wrapper-scroll-y my-custom-scrollbar">
        <table class="table table-bordered table-striped mb-0">
          <thead>
            <tr>
              <th>Implementation Name</th>
              <!-- <th>Implementation Link</th> -->
              <th>Template Name</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Estimated Hours</th>
              <th>% of Budget</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of implementations">
              <td><a [href]="'../implementation-detail/' + item.ImplementationEstimateID">{{
                  item.SFImplementationName }}</a> &nbsp; <a [href]="item.ImplementationLink"><fa-icon [icon]="faSalesforce"></fa-icon></a></td>
              <td>{{ item.TemplateName }}</td>
              <td>{{ item.ImplementationStartDate | date: 'MM-dd-yyyy' }}</td>
              <td>{{ item.ImplementationEndDate | date: 'MM-dd-yyyy'}}</td>
              <td>{{ item.TotalHours }}</td>
              <td [ngClass]="{ 'text-danger': (item.HoursSpent / item.TotalHours) >= .75 } "> {{ 
              (item.HoursSpent / item.TotalHours)| percent }}</td>
              <td class="actions-column">
                <a class="btn btn-primary" style="margin-right:5px" type="button"
                  [routerLink]="['../implementations/'+ item.ImplementationEstimateID]"
                  tooltip="Edit Overrides">
                  <fa-icon [icon]="faPen"></fa-icon></a>
                <button class="btn btn-danger" type="button" (click)="showDeleteAssignmentModal(deleteTemplate, item.ImplementationEstimateID)"> <fa-icon
                    [icon]="faTrash"></fa-icon></button>

              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#ffeb3b" type="pacman" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>

<ng-template #deleteTemplate>
  <div class="modal-body text-center">
    <p><b><fa-icon [icon]="faTriangleExclamation" class="text-danger"></fa-icon>&nbsp; Do you want archive this implementation?</b></p>
    <p>This action will archive the implementation through soft-delete. Time Entries will still be present.</p>
    <button type="button" class="btn btn-danger m-1" (click)="deleteImplementation()">Yes</button>
    <button type="button" class="btn btn-primary m-1" (click)="modalRef?.hide()">No</button>
  </div>
</ng-template>
