import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { BrowserModule } from '@angular/platform-browser';

import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDropdownModule, BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FailedComponent } from './failed/failed.component';
import { AzureadService } from './services/azuread.service';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { TemplateAssignmentListComponent } from './templates/template-assignment-list/template-assignment-list.component';
import { TestService } from './services/test.service';
import { DataService } from './services/data.service';
import { SalesforceService } from './services/salesforce.service';
import { HttpService } from './services/http.service';
import { TemplateDetailTabViewComponent } from './templates/template-detail-tab-view-old/template-detail-tab-view.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TimeEntryScreenComponent } from './time-entry/time-entry-screen/time-entry-screen.component';
import { TemplateAssignmentComponent } from './templates/template-assignment/template-assignment.component';
import { ImplementationAssignmentModalComponent } from './templates/implementation-assignment-modal/implementation-assignment-modal.component';
import { ImplementationDetailComponent } from './implementations/implementation-detail/implementation-detail.component';
import { FilterByRolePipe } from './pipes/FilterByRolePipe';
import { StorageService } from './services/storage.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { TemplateListComponent } from './templates/template-list/template-list.component';
import { TemplateDetailComponent } from './templates/template-detail/template-detail.component';
import { ReportListComponent } from './reports/report-list/report-list.component';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import { TemplateCreateModalComponent } from './templates/template-create-modal/template-create-modal.component';
import { UniquePipe } from './pipes/UniquePipe';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DeliverablesEditorComponent } from './admin/deliverables-editor/deliverables-editor.component';
import { CategoriesEditorComponent } from './admin/categories-editor/categories-editor.component';
import { CategoriesModalComponent } from './admin/categories-modal/categories-modal.component';
import { TimesheetReviewComponent } from './time-entry/timesheet-review/timesheet-review.component';
import { TimeEntryEditModalComponent } from './time-entry/time-entry-edit-modal/time-entry-edit-modal.component';
import { EntriesGridComponent } from './time-entry/entries-grid/entries-grid.component';
import { GroupedEntriesGridComponent } from './time-entry/grouped-entries-grid/grouped-entries-grid.component';
import { PreferenceService } from './services/preferences.service';
import { PreferenceSettingsComponent } from './preferences/preference-settings/preference-settings.component';
import { SupportComponent } from './support/support/support.component';
import { UnauthorizedComponent } from './misc/unauthorized/unauthorized.component';
import { ConfirmModalComponent } from './time-entry/confirm-modal/confirm-modal.component';
import { ImplementationsListComponent } from './implementations/implementations-list/implementations-list.component';
import { CalendarEventService } from './services/calendar-event.service';
import { CalendarEventAssigmentComponent } from './calendar-events/calendar-event-assigment/calendar-event-assigment.component';
import { MaterialGridComponent } from './grid/material-grid/material-grid.component';
import { UtcToLocalDatePipe } from './pipes/UtcToLocalPipe';
import { MomentDatePipe } from './pipes/MomentDatePipe';
import { SfTypeaheadComponent } from './components/sf-typeahead/sf-typeahead.component';
import { MenuBarComponent } from './nav/menu-bar/menu-bar.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ImplementationDetailSfComponent } from './implementations/implementation-detail-sf/implementation-detail-sf.component';
//import { CstDatepickerComponent } from './components/cst-datepicker/cst-datepicker.component';
import { CstDropdown } from './components/cst-dropdown/cst-dropdown.component';
import { Router, RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UtilityService } from './services/utility.service';
import { NoteEntryModalComponent } from './notes/note-entry-modal/note-entry-modal.component';
import { NotesService } from './services/notes.service';
import { QuillModule } from 'ngx-quill';
import { ImplementationsListMatComponent } from './implementations/implementations-list-mat/implementations-list-mat.component';
import { AddAttachmentModalComponent } from './attachments/add-attachment-modal/add-attachment-modal.component';
import { AttachmentsListComponent } from './attachments/attachments-list/attachments-list.component';
import { AttachmentService } from './services/attachment.service';
import { ImplementationDetailSfV2Component } from './implementations/implementation-detail-sf-v2/implementation-detail-sf-v2.component';
import { TableModule } from 'primeng/table';
import { CalendarEventsListComponent } from './calendar-events/calendar-events-list/calendar-events-list.component';
import { NotesListComponent } from './notes/notes-list/notes-list.component';
import { CardModule } from 'primeng/card';
import { TimelineModule } from 'primeng/timeline';
import { TooltipModule } from 'primeng/tooltip';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { RaidMatrixComponent } from './implementations/raid-matrix/raid-matrix.component';
import { RaidMatrixItemComponent } from './implementations/raid-matrix-item/raid-matrix-item.component';
import { DataViewModule } from 'primeng/dataview';
import { ToggleButtonModule } from 'primeng/togglebutton'
import { ChartModule } from 'primeng/chart';


const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  //noop
}

export function MSALInstanceFactory() {
  return new PublicClientApplication({
    auth: {
      clientId: 'd7c10705-7cda-44cc-9e73-51b1a43f0a61',
      authority:
        'https://login.microsoftonline.com/8d9a783e-3161-415f-a69a-bc4ac4962e13', // Prod environment.
      redirectUri: '/',
      postLogoutRedirectUri: '/',
    
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false, // set to true for IE 11 or Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          //uncomment to debug profile account objects
          // if (containsPii || environment.apiEndpoint.indexOf('localhost') == -1)
          //   return;
          if (containsPii || true) return;

          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
            default:
              console.log('default', message);
          }
        },
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', [
    'user.read',
  ]);

  //adds the token to all requests with /api/. configured under 'expose an api' on the service principal. without this you'll get 401s
  protectedResourceMap.set(environment.apiEndpoint, [
    'api://d7c10705-7cda-44cc-9e73-51b1a43f0a61/access_as_user',
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],
    },
    loginFailedRoute: '/login-failed',
  };
}

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: ['YYYY-MM-DD', 'YYYY/MM/DD', 'YYYY-MM', 'YYYY-MM-DDTHH:mm:ssZ'],
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'YYYY-MM-DD',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    FailedComponent,
    HomeComponent,
    ProfileComponent,
    TemplateAssignmentListComponent,
    TemplateDetailComponent,
    TemplateDetailTabViewComponent,
    TimeEntryScreenComponent,
    TemplateAssignmentComponent,
    ImplementationAssignmentModalComponent,
    ImplementationDetailComponent,
    TemplateListComponent,
    ReportListComponent,
    TemplateCreateModalComponent,
    FilterByRolePipe,
    UniquePipe,
    UtcToLocalDatePipe,
    MomentDatePipe,
    DeliverablesEditorComponent,
    CategoriesEditorComponent,
    CategoriesModalComponent,
    TimesheetReviewComponent,
    TimeEntryEditModalComponent,
    EntriesGridComponent,
    GroupedEntriesGridComponent,
    PreferenceSettingsComponent,
    SupportComponent,
    UnauthorizedComponent,
    ConfirmModalComponent,
    ImplementationsListComponent,
    CalendarEventAssigmentComponent,
    MaterialGridComponent,
    SfTypeaheadComponent,
    MenuBarComponent,
    ImplementationDetailSfComponent,
    CstDropdown,
    ImplementationAssignmentModalComponent,
    CategoriesModalComponent,
    NoteEntryModalComponent,
    ImplementationsListMatComponent,
    AddAttachmentModalComponent,
    AttachmentsListComponent,
    ImplementationDetailSfV2Component,
    CalendarEventsListComponent,
    NotesListComponent,
    RaidMatrixComponent,
    RaidMatrixItemComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MsalModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonsModule.forRoot(),
    BsDropdownModule.forRoot(),
    TypeaheadModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    AccordionModule.forRoot(),
    FontAwesomeModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    NgxBootstrapMultiselectModule,
    
    //MatTableModule,
    CollapseModule.forRoot(),
    NgxDatatableModule,
    RouterModule,
    QuillModule.forRoot(),
    TableModule,
    CardModule,
    TimelineModule,
    TooltipModule,
    DataViewModule,
    ToggleButtonModule,
    ChartModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    // {
    //   provide: UrlSerializer,
    //   useClass: CustomUrlSerializer
    // },
    HttpService,
    AzureadService,
    DataService,
    UtilityService,
    SalesforceService,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    TestService,
    BsModalService,
    StorageService,
    PreferenceService,
    CalendarEventService,
    NotesService,
    AttachmentService,
    // { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    // {
    //   provide: MAT_DATE_FORMATS,
    //   useValue: MY_DATE_FORMATS,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})



export class AppModule {}
