import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faPen, faQuestionCircle, faTrash, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DeliverableData, ImplementationEstimateOverride, ImplementationMonthData, RoleData } from 'src/app/objects/Interfaces';
import { DataService } from 'src/app/services/data.service';
import { SalesforceService } from 'src/app/services/salesforce.service';

@Component({
  selector: 'app-implementation-detail',
  templateUrl: './implementation-detail.component.html',
  styleUrls: ['./implementation-detail.component.scss'],
})
export class ImplementationDetailComponent implements OnInit {
  params: any;
  estimate: any;
  estimatesByRole: any[] = [];
  faTrash = faTrash;
  faPen = faPen;
  faQuestionCircle = faQuestionCircle;
  faTriangleExclamation = faTriangleExclamation;
  monthCounter: number = 0;
  sfLink: string;
  editedItems: any[] = [];

  pivotData: any[] = [];

  modalRef?: BsModalRef;

  @Input() implementationEstimateID: number = null;

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private salesforceService: SalesforceService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: BsModalService,
  ) {}

  ngOnInit(): void {
    if (this.implementationEstimateID == null) {
      this.route.params.subscribe((params) => {
        //this.modalService.onHide.subscribe(() => this.router.navigate(["/implementations-list"]));
        this.loadData(params['Id']);
      });
    } else {
      this.loadData(this.implementationEstimateID);
    }
  }

  loadData(id: any) {
    this.spinnerService.show();
    this.dataService
      .getImplementationEstimate(id)
      .subscribe((x) => {
        this.estimate = x;
        this.sfLink = this.salesforceService.getSFImplementationLink(x.ImplementationEstimate.SFImplementationID);

        this.doPivot()

        //this.spinnerService.hide();
      }, error => console.log(error));
  }

  doPivot() {
    this.pivotData = [];

    this.estimate.Roles.forEach((role) => {
      let roleParent: RoleData = {
        Role: role,
        Deliverables: [],
      };

      let overridesForRole =
        this.estimate.ImplementationEstimate.ImplementationEstimateOverrides.filter(
          (y) => y.RoleID == role.RoleID
        );

      this.estimate.Deliverables.forEach((d) => {
        let deliverableData: DeliverableData = {
          Deliverable: d,
          Months: [],
        };

        let devOverrides = overridesForRole.filter(
          (y) => y.DeliverableID == d.DeliverableID
        );

        this.estimate.MonthColumns.forEach((month) => {
          let monthData: ImplementationMonthData = {
            Month: month,
            Override: null
          };


          //let editedItem = this.editedItems.find(y => )



          let override = devOverrides.find((x) => x.MonthStart == month.MonthIndex);

          if (!override) { //create a stub
            monthData.Override  = {
              DeliverableID: d.DeliverableID,
              EstimatedHours: 0,
              ImplementationEstimateID: this.estimate.ImplementationEstimate.ImplementationEstimateID,
              ImplementationEstimateOverrideID: 0,
              MonthStart: month.MonthIndex,
              RoleID: role.RoleID
            };
          } else {
            monthData.Override = override;
          }



          deliverableData.Months.push(monthData);
        }); //end month loop

        roleParent.Deliverables.push(deliverableData);
      }); //end deliverable loop

      this.pivotData.push(roleParent);
    }); //end role loop

    this.applyEdits();

    this.spinnerService.hide();
  }

  editHours(override: ImplementationEstimateOverride) {
    const alreadyExists = this.editedItems.findIndex(x => x.DeliverableID == override.DeliverableID &&  x.RoleID == override.RoleID && x.MonthStart == override.MonthStart);

    if (alreadyExists > -1) {
      this.editedItems.splice(alreadyExists, 1);
    }

    this.editedItems.push(override);
  }

  saveChanges() {

    this.spinnerService.show();

    let body = {
      ImplementationEstimateID: this.estimate?.ImplementationEstimate.ImplementationEstimateID,
      ConfirmMessage: this.estimate?.ImplementationEstimate.ConfirmMessage,
      ImplementationEstimateOverrides: this.editedItems
    };

    this.dataService.saveOverrides(this.estimate.ImplementationEstimate.ImplementationEstimateID, body).subscribe(result => {
      this.estimate = result;

      this.editedItems = [];
      this.doPivot();
    }, err => this.handleError(err));
    
    this.spinnerService.hide();
    this.toastr.success('Changes saved.');
  }

  handleError(err) {
    alert(err.message);
    //alert(err.error);
  }

  addMonth() {

    let max = this.estimate.MonthColumns.reduce(function (prev, current) {
      if (+current.MonthIndex > +prev.MonthIndex) {
        return current;
      } else {
        return prev;
      }
    });

    let monthColumn = {
      MonthIndex: max.MonthIndex + 1,
      MonthName: 'Month ' + max.MonthIndex,
    };

    this.spinnerService.show();
    this.estimate.MonthColumns.push(monthColumn);
    this.doPivot(); //push the change to the moddel.

  }

  applyEdits() {
    this.editedItems.forEach(editedItem => {

      const role = this.pivotData.find((r) => r.Role.RoleID === editedItem.RoleID);
      if (role) {
        const deliverable = role.Deliverables.find(
          (d) => d.Deliverable.DeliverableID === editedItem.DeliverableID
        );

        if (deliverable) {
          const monthIndex = editedItem.MonthStart -1;
          const month = deliverable.Months[monthIndex];
          if (month) {
            month.Override = editedItem;
          }
        }
      }
    });
  }

  showDeleteAssignmentModal(template: TemplateRef<any>, id: any) {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' })
    this.modalRef.id = id;
  }


  deleteImplementation(): void {
    const id = this.modalRef?.id;

    this.dataService.deleteImplementationEstimate(id).subscribe(x => {

      this.modalRef.hide();
      const previousUrl = window.history.back();
    }, err => {
      alert(err.error);
    });
  }

}

