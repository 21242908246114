

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

const api = "https://localhost:44368/api/Salesforce/";

@Injectable({
  providedIn: 'root',
})
export class SalesforceService {
  constructor(protected http: HttpService) {}


  getAllProducts(): Observable<any> {
    const url = `{api}salesforce/products2`
    return this.http.get(url);
  }

  getImplementations(): Observable<any> {
    const url = `{api}salesforce/implementations`
    return this.http.get(url);
  }

  getSFImplementationLink(id: any): string {
    return `https://o3solutions.lightning.force.com/lightning/r/Implementation__c/${id}/view`;
  }

  saveSfImplementation(sfImplementation: any): Observable<any> {
    const url = `{api}salesforce/implementation`;
    return this.http.post(url, JSON.stringify(sfImplementation));
  }

}
