import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { faChevronDown, faChevronRight, faChevronUp, faCopy, faLock, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { SortType } from '@swimlane/ngx-datatable';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-grouped-entries-grid',
  templateUrl: './grouped-entries-grid.component.html',
  styleUrls: ['./grouped-entries-grid.component.scss'],
})
export class GroupedEntriesGridComponent implements OnChanges{


  @Input('rowData') rowData: any[] = [];
  @Input('showCopyButton') showCopyButton = false;
  @Input('showDeleteButton') showDeleteButton = false;
  @Input('showEditButton') showEditButton = false;

  @Output() openTimeEntryEditModalEvent: EventEmitter<any> = new EventEmitter();
  @Output() copyEntryEvent: EventEmitter<any> = new EventEmitter();
  @Output() deleteEntryEvent: EventEmitter<any> = new EventEmitter();

  faCopy = faCopy;
  faPen = faPen;
  faTrash = faTrash;
  faLock = faLock;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faChevronRight = faChevronRight;

  sortType = SortType.multi;

  rowGroups: { [key: string]: { isExpanded: boolean, items: any[] } } = {};
  currentExpandedGroup: string | null = null;

  ngOnChanges(changes: SimpleChanges): void {
      if ('rowData' in changes && this.rowData) {
        this.groupRows();
      }
  }

  openTimeEntryEditModal(row: any): void {
    this.openTimeEntryEditModalEvent.emit(row);
  }

  copyEntry(row: any) {
    this.copyEntryEvent.emit(row);
  }

  deleteEntry(row: any) {
    this.deleteEntryEvent.emit(row);
  }

  groupRows() {
    this.rowGroups = this.rowData.reduce((result, item) => {
      const value = item['EntryDate'];
      if (!result[value]) {
        result[value] = { isExpanded: false, items: [] };
      }
      result[value].items.push(item);
      return result;
    }, {} as { [key: string]: { isExpanded: boolean, items: any[] } });
  }

  groupKeys(): string[] {
    return Object.keys(this.rowGroups);
  }

  // toggleGroup(groupKey: string): void {
  //   this.rowGroups[groupKey].isExpanded = !this.rowGroups[groupKey].isExpanded;
  // }
  toggleGroup(groupKey: string): void {
    if (this.currentExpandedGroup === groupKey) {
      this.currentExpandedGroup = null; // Collapse the currently expanded group
    } else {
      this.currentExpandedGroup = groupKey; // Expand the clicked group
    }
  }

  calculateTotalHours(groupItems: any[]): number {
    return groupItems.reduce((total, item) => total + (item.TimeSpent || 0), 0);
  }

  groupedRowClass(isExpanded = false) {

  }
}
