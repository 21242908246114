<label>{{ label }}</label>
<select class="form-control"
        [ngClass]="{'is-invalid': control.invalid && control.touched}"
        [formControl]="control"
        [name]="controlName"
        [id]="controlName"
        (change)="onValueChange($event)">
    <option [value]=""></option>
    <option *ngFor="let option of options" [value]="getValue(option)">
        {{ getDisplay(option) }}
    </option>
</select>
