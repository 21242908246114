<div class="container-fluid">
  <div class="row">
    <h3>Reports</h3>
  </div>
  <div class="row">
    <div class="col-12">
      <tabset>
        <tab [heading]="'Instructions'">
          <ul>
            <li>To use reports, please login to <b>Qlik Sense Cloud</b> at the following URL: <a
                href="https://o3insight.us.qlikcloud.com/" target="_blank">Qlik Sense Login</a></li>
            <li>Once logged in you may close the tab and return to the application and <a href="/reports"
                (click)="reloadPage()" target="_self">Click Here</a> to refresh the page.</li>
          </ul>
          <p class="p-5"></p>
          <p class="p-5"></p>
        </tab>
        <tab [heading]="'Summary'">
          <iframe height="100%" width="100%" (error)="handleIFrameError()"
            src="https://o3insight.us.qlikcloud.com/single/?appid=044c4ca9-7e7a-4f61-bebd-17d31f0cb855&sheet=c93578e2-bb24-44c5-ab5a-2f458693ac29&theme=horizon&opt=ctxmenu,currsel"></iframe>
        </tab>
        <tab [heading]="'Finance'">
          <iframe height="100%" width="100%" (error)="handleIFrameError()"
            src="https://o3insight.us.qlikcloud.com/single/?appid=044c4ca9-7e7a-4f61-bebd-17d31f0cb855&sheet=1b911b39-6730-40ca-b3a4-af00f5274071&theme=horizon&opt=ctxmenu,currsel"></iframe>
        </tab>
      </tabset>
    </div>
  </div>


</div>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#ffeb3b" type="pacman" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>