  <div class="modal-header">
    <h4 class="modal-title pull-left">Editing {{ categoryName }}</h4>
  </div>
  <div class="modal-body">
    <div class="form-inline">
      <label for="Name">Name:</label>
      <input class="form-control" type="text" name="Name" [(ngModel)]="selectedCategory.Name" required="required">
    </div>
    <div class="row">
      <div class="table-responsive table-wrapper-scroll-y my-custom-scrollbar">
        <table class="table table-bordered table-striped mb-0">
          <thead>
            <th>ID</th>
            <th>Name</th>
            <th>Is Billable</th>
          </thead>
          <tbody>
            <tr *ngFor="let subcategory of selectedCategory.InternalTimeSubcategories">
              <td>{{ subcategory.InternalTimeSubcategoryID }}</td>
              <td><input class="form-control" type="text" [(ngModel)]="subcategory.Name"></td>
              <td><input class="form-control-check" type="checkbox" [(ngModel)]="subcategory.IsBillable"></td>
            </tr>
          </tbody>
          </table>
          </div>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <button type="button" class="btn btn-success pull-left"  (click)="addNewSubcategory()"><fa-icon [icon]="faPlus"></fa-icon> Subcategory</button>
    <div>
      <button type="button" class="btn btn-primary pull-right" (click)="saveInternalCategory()">Submit</button> &nbsp;
      <button type="button" class="btn btn-secondary pull-right" aria-label="Close" name="Close"
        (click)="bsModalRef?.hide()">Close</button>
    </div>

  </div>
