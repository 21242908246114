import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  faTrash,
  faPen,
  faPlus,
  faBoxArchive,
  faArchive,
} from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NoteEntryModalComponent } from 'src/app/notes/note-entry-modal/note-entry-modal.component';
import { NotesService } from 'src/app/services/notes.service';

@Component({
  selector: 'app-raid-matrix-item',
  templateUrl: './raid-matrix-item.component.html',
  styleUrls: ['./raid-matrix-item.component.scss'],
})
export class RaidMatrixItemComponent implements AfterViewInit, OnChanges {
  @Input() implementationEstimate: any;
  @Input() noteType: any;
  @Input() showArchived: boolean = false;

  @Output() refresh: EventEmitter<any> = new EventEmitter<any>;

  raidNotes: any[];
  allNotes: any[];

  config: any;

  modalRef?: BsModalRef;

  faTrash = faTrash;
  faPen = faPen;
  faPlus = faPlus;
  faBoxArchive = faBoxArchive;
  faArchive = faArchive;

  constructor(
    private notesService: NotesService,
    private modalService: BsModalService
  ) {}

  ngAfterViewInit(): void {
    this.load();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['showArchived'] && !changes['showArchived'].firstChange) {
      //this.filterNotes();
    }
  }

  // filterNotes() {
  //   this.raidNotes = this.showArchived
  //     ? this.allNotes
  //     : this.allNotes.filter((note) => note.NotePriority != 5);
  //}

  load(): void {
    this.notesService
      .getByNoteTypeID(
        this.implementationEstimate?.ImplementationEstimateID,
        this.noteType?.NoteTypeID
      )
      .subscribe((x) => {
        this.config = x;
        this.allNotes = x.Data;
        
        //restore default behavior, revisit hide later
        this.raidNotes = this.allNotes;
        //this.filterNotes();
      });
  }

  getPriorityClass(priority: string): string {
    return priority;
  }

  addItem() {
    const initialState = {
      ImplementationEstimate: this.implementationEstimate,
      NoteTypeID: this.noteType.NoteTypeID,
      NoteType: this.noteType,
      showNoteType: false,
      showPriorities: true,
      showTitle: true,
    };

    this.modalRef = this.modalService.show(NoteEntryModalComponent, {
      class: 'modal-xl',
      initialState: initialState,
    });

    this.modalRef.onHide.subscribe((x) => {
      //todo: modify so only on save.
      this.load();
      this.refresh.emit(true);
    });
  }

  editItem(item: any) {
    console.log(item);

    //TODO GET RID OF HACKS... use the Note instead, load config in modal.
    const dto = {
      Note: item,
      Attachments: item.Attachments,
      NoteTypes: this.config.NoteTypes,
      Priorities: this.config.Priorities,
    };

    const initialState = {
      ImplementationEstimate: this.implementationEstimate,
      NoteTypeID: this.noteType.NoteTypeID,
      noteDto: dto,
      attachments: dto.Attachments,
      priorities: this.config.Priorities,
      showNoteType: false,
      showPriorities: true,
      showTitle: true,
      isEdit: true,
    };

    this.modalRef = this.modalService.show(NoteEntryModalComponent, {
      class: 'modal-xl',
      initialState: initialState,
    });

    this.modalRef.onHide.subscribe((x) => {
      this.load();
      this.refresh.emit(true);
    });
  }

  // deleteItem(item: any) {
  //   // Logic to delete item
  // }

  // archiveItem(item: any) {
  //   // noop
  // }
}
