import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { faFloppyDisk, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-material-grid',
  templateUrl: './material-grid.component.html',
  styleUrls: ['./material-grid.component.scss'],
})
export class MaterialGridComponent {
  constructor(private spinnerService: NgxSpinnerService) {}

  @Input() data: any[] = [];
  @Input() columns: MatColumn[] = [];
  @Input() showSaveButton: boolean = false;
  @Input() showDeleteButton: boolean = false;
  @Input() showEditButton: boolean = false;
  @Input() showModalAction: boolean = false;
  @Input() modalActionText: string = '';
  @Input() isLoading: boolean = false;
  @Input() numberFormat: string = '1.0-2';
  @Input() dateFormat: string = 'MM/dd/yyyy';
  @Input() isFilterable: boolean = true;

  @Output() hyperlinkClicked = new EventEmitter<{
    column: MatColumn;
    rowData: any;
  }>();

  @Output() saveClicked = new EventEmitter<any>();

  @Output() deleteClicked = new EventEmitter<any>();

  @Output() editClicked = new EventEmitter<any>();
  
  @Output() modalAction = new EventEmitter<any>();

  @ViewChild('grid') grid: Table;

  faTrash = faTrash;
  faFloppyDisk = faFloppyDisk;
  faPen = faPen;

  doReset() {
    this.grid.reset();
  }

  onHyperlinkClick(column: MatColumn, rowData: any, event: MouseEvent) {
     this.hyperlinkClicked.emit({ column, rowData });
  }

  onSave(rowData: any) {
    this.saveClicked.emit(rowData);
  }

  onDelete(rowData: any) {
    this.deleteClicked.emit(rowData);
  }

  onEdit(rowData: any) {
    this.editClicked.emit(rowData);
  }

  onModalAction(rowData: any) {      
    this.modalAction.emit(rowData);
  }
}

export interface HyperlinkEvent {
  column: MatColumn;
  element: any;
}

export interface MatColumn {
  key: string;
  header: string;
  dataType?: string;
  isHyperlink: boolean;
  isHtml: boolean;
}
