import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DataService } from 'src/app/services/data.service';
import { SalesforceService } from 'src/app/services/salesforce.service';

@Component({
  selector: 'app-implementation-assignment-modal',
  templateUrl: './implementation-assignment-modal.component.html',
  styleUrls: ['./implementation-assignment-modal.component.scss']
})
export class ImplementationAssignmentModalComponent {
  params: any;
  products: any;

  selectedImplementationName: any;
  selectedImplementationObj: any;
  implementations: any;
  list: any[] = [];
  public content: any;
  public template: any;

  constructor(public modalRef: BsModalRef, private modalService: BsModalService,
    private salesforceService: SalesforceService, private dataService: DataService) {}

  ngOnInit(): void {
      this.salesforceService.getImplementations().subscribe(x => {
        this.implementations = x.Implementations;
      });
  }

  doSubmit() {

    let error = false;

    if (!this.selectedImplementationObj?.id) {
      alert('Implementation is required!');
      error = true;
      return;
    }

    if (!this.selectedImplementationObj.implementation_Start_Date__c) {
      console.log('Missing start date:', this.selectedImplementationObj.implementation_Start_Date__c);
      error = true;
      alert('Implementation start date is not completed. Please set in Salesforce!');
    }

    if (!this.selectedImplementationObj.implementation_End_Date__c) {
      console.log('Missing end date:', this.selectedImplementationObj.implementation_End_Date__c);
      error = true;
      alert('Implementation end date is not completed. Please set in Salesforce!');
    }

    if (error) {
      return;
    }

    this.dataService.addTemplateToImplementationAssociation({
      TemplateID: this.template.TemplateID,
      SFImplementationID: this.selectedImplementationObj.id}).subscribe(x => {
      this.modalRef.hide();
    }, err => {
      alert(`Error: ${err.error}`);
    });
  }

  impSelected(item: any) {
    this.selectedImplementationObj = item;
  }
}
