import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faCopy, faPen, faTrash, faLock } from '@fortawesome/free-solid-svg-icons';
import { ColumnMode, SortType } from '@swimlane/ngx-datatable'

@Component({
  selector: 'app-entries-grid',
  templateUrl: './entries-grid.component.html',
  styleUrls: ['./entries-grid.component.scss']
})
export class EntriesGridComponent {


  @Input('rowData') rowData = [];
  @Input('showCopyButton') showCopyButton = false;
  @Input('showDeleteButton') showDeleteButton = false;
  @Input('showEditButton') showEditButton = false;

  @Output() openTimeEntryEditModalEvent: EventEmitter<any> = new EventEmitter();
  @Output() copyEntryEvent: EventEmitter<any> = new EventEmitter();
  @Output() deleteEntryEvent: EventEmitter<any> = new EventEmitter();

  faCopy = faCopy;
  faPen = faPen;
  faTrash = faTrash;
  faLock = faLock;

  sortType = SortType.multi;

  openTimeEntryEditModal(row: any) {
    // Your existing logic here
    // Emit the event when it should be triggered
    this.openTimeEntryEditModalEvent.emit(row);
  }

  copyEntry(row: any) {
    // Your existing logic here
    // Emit the event when it should be triggered
    this.copyEntryEvent.emit(row);
  }

  deleteEntry(row: any) {
    // Your existing logic here
    // Emit the event when it should be triggered
    this.deleteEntryEvent.emit(row);
  }
}
