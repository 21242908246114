import { Component, OnInit } from '@angular/core';
import { faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/data.service';
import { TemplateCreateModalComponent } from '../template-create-modal/template-create-modal.component';

@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss'],
})
export class TemplateListComponent implements OnInit {
  templates: any[];

  modalRef?: BsModalRef;

  faTrash = faTrash;
  faPen = faPen;
  faPlus = faPlus;

  constructor(
    private dataService: DataService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: BsModalService,
  ) {}

  ngOnInit(): void {
    this.spinnerService.show();
    this.dataService.getAllTemplates().subscribe((x) => {
      this.spinnerService.hide();
      this.templates = x;
    }, err => this.handleError(err));
  }

  deleteEstimate($event) {
    this.spinnerService.show();
    this.dataService.deleteTemplate($event.TemplateID).subscribe(
      (x) => {
        this.spinnerService.hide();
        this.toastr.success('Deleted Template');
      },
      (err) => this.handleError(err)
    );
  }

  handleError(err: any) {
    alert(err.error);
    this.spinnerService.hide();
  }

  showCreateModal() {
    const initialState = {
      class: 'modal-lg'
    };

    this.modalRef = this.modalService.show(TemplateCreateModalComponent, initialState);
  }
}
