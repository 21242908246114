import { Component, OnInit } from '@angular/core';
import { AzureadService } from '../services/azuread.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  constructor(private azuread: AzureadService) {}

  profile!: any;

  ngOnInit(): void {
    this.azuread.getProfile().subscribe(r => this.profile = r);
  }
}
