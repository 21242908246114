// TypeScript Code
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { faTrash, faWarning } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatColumn } from 'src/app/grid/material-grid/material-grid.component';
import { AttachmentService } from 'src/app/services/attachment.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-attachments-list',
  templateUrl: './attachments-list.component.html',
  styleUrls: ['./attachments-list.component.scss'],
})
export class AttachmentsListComponent implements OnInit {
  @Input('id') id: number;
  @Input('associationType') associationType: string;

  attachments: any;
  attachmentTypes: any[] = [];
  
  modalRef?: BsModalRef;

  
  faTrash = faTrash;
  faWarning = faWarning;
  

  deleteId: number;
  columns: any;
  isLoading: boolean = false;

  constructor(
    private attachmentService: AttachmentService,
    private spinnerService: NgxSpinnerService,
    private modalService: BsModalService,
    private utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    this.modalService.onHide.subscribe(() => this.loadData());
    this.loadData();
  }

  refresh() {
    this.loadData();
  }

  loadData() {
    this.isLoading = true;
    this.attachmentService.getList(this.id, this.associationType).subscribe(
      (x) => {
        this.attachments = x;
        this.columns = this.utilityService.getGridColumns(this.attachments?.GridConfig?.GridColumns)
 
        this.isLoading = false;
      },
      (error) => alert(error)
    );
  }

  downloadAttachment($event: { column: MatColumn; rowData: any }) {
    this.attachmentService.downloadAttachment($event.rowData.AttachmentID).subscribe((x) => {
      window.location.href = x.Url;
    });
  }

  delete(attachmentID: number, template: TemplateRef<void>) {
    // Open modal without setting DeleteId
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.modalRef.content = { attachmentID }; // Pass attachmentID to modal content
  }
  
  confirmDelete(attachmentID: number) {
    // Proceed with deletion logic using attachmentID
    this.attachmentService.deleteAttachment(attachmentID).subscribe(
      () => {
        // Refresh or reload data if needed
        this.loadData();
        this.modalRef?.hide();
      },
      (error) => {
        alert(error);
        this.modalRef?.hide();
      }
    );
  }
}
