import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class NotesService {

  constructor(protected http: HttpService) {}

  //templates
  createNote(): Observable<any> {
      return this.http.get(`{api}notes/create`);
  }

  saveNote(noteDto: any): Observable<any> {
    return this.http.post(`{api}notes`, JSON.stringify(noteDto));
  }

  implementationNotes(implementationEstimateID: any) : Observable<any> {
    return this.http.get(`{api}notes/implementation/` + implementationEstimateID);
  }

  getByNoteTypeName(implementationEstimateID: any, noteTypeName: any){
    return this.http.get(`{api}notes/implementation/` + implementationEstimateID + `/note-type-name/` + noteTypeName);
  }

  getByNoteTypeID(implementationEstimateID: any, noteTypeID: any){
    return this.http.get(`{api}notes/implementation/` + implementationEstimateID + `/note-type/` + noteTypeID);
  }

  getByCategory(implementationEstimateID: any, category: any) {
    return this.http.get(`{api}notes/implementation/` + implementationEstimateID + `/category/`+ category);
  }

  getNoteTypes(categoryName: string) {
    return this.http.get(`{api}notes/note-types`, { params: {
      categoryName: categoryName
    } });
  }

  getRaidBarChart(implementationEstimateID: any) {
    return this.http.get(`{api}notes/raid-bar-chart/`+implementationEstimateID);
  }

  getPriorties() {
    return this.http.get(`{api}notes/priorities`);
  }

}
