import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';

@Component({
  selector: 'cst-dropdown',
  templateUrl: './cst-dropdown.component.html',
  styleUrls: ['./cst-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CstDropdown),
      multi: true
    }
  ]
})
export class CstDropdown implements ControlValueAccessor, OnChanges {
  @Input() options: any[] = [];
  @Input() label: string = '';
  @Input() controlName: string = '';
  @Input() valueProperty: string = 'id'; // Name of the property for the value
  @Input() displayProperty: string = 'name'; // Name of the property for the display text

  control: FormControl = new FormControl();

  private onChange: (value: any) => void = () => {};
  private onTouched: () => void = () => {};

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) {
    //  console.log('Options updated:', this.options);
    }
  }

  writeValue(value: any): void {
    this.control.setValue(value, { emitEvent: true });
    
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
    this.control.valueChanges.subscribe(value => this.onChange(value));
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  onValueChange(event: Event): void {
    const target = event.target as HTMLSelectElement;
    this.control.setValue(target.value);
    this.onChange(target.value);
  }

  getValue(option: any): any {
    return option[this.valueProperty];
  }

  getDisplay(option: any): any {
    return option[this.displayProperty];
  }
}
