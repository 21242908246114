<div id="sidebar" [collapse]="isCollapsed" class="border-end">
    <div id="sidebar-nav" class="list-group border-0 rounded-0 text-sm-start">
      <div class="d-flex align-items-center">
        <img class="o3-icon" src="/assets/o3-icon.png" alt="O3" />
        <a class="navbar-brand" style="margin-left:5px" href="#">{{ title }}</a>
      </div>
  
      <div class="dropdown-divider"></div>
      <div class="list-group-item border-end-0 d-inline-block text-truncate">
        <a class="nav-link"><i class="bi bi-clock"></i> Time Entries</a>
        <ul class="nav flex-column">
          <li class="nav-item">
            <a class="nav-link" routerLink="/time-entry" [ngClass]="{'font-weight-bold': isActive('/time-entry')}">Time Entry</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/timesheet-review" [ngClass]="{'font-weight-bold': isActive('/timesheet-review')}">Timesheets</a>
          </li>
        </ul>
      </div>
      <div class="dropdown-divider"></div>
      <div class="list-group-item border-end-0 rounded-0 d-inline-block text-truncate">
        <a class="nav-link"><i class="bi bi-bricks"></i> Templates</a>
        <ul class="nav flex-column">
          <li class="nav-item">
            <a class="nav-link" routerLink="/template-assignment-list" [ngClass]="{'font-weight-bold': isActive('/template-assignment-list')}">Assignments</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/template-list" [ngClass]="{'font-weight-bold': isActive('/template-list')}">View/Edit</a>
          </li>
        </ul>
      </div>
      <div class="dropdown-divider"></div>
      <div class="list-group-item border-end-0 rounded-0 d-inline-block text-truncate">
        <a class="nav-link" routerLink="/implementations-list">
          <i class="bi bi-list"></i> Implementations
        </a>
      </div>
      <div class="dropdown-divider"></div>
      <div class="list-group-item border-end-0 rounded-0 d-inline-block text-truncate">
        <a class="nav-link" routerLink="/profile">
          <i class="bi bi-gear"></i> Admin
        </a>
        <ul class="nav flex-column">
          <li class="nav-item">
            <a class="nav-link" routerLink="/profile" [ngClass]="{'font-weight-bold': isActive('/profile')}">My Profile</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/reports" [ngClass]="{'font-weight-bold': isActive('/reports')}">Reports</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/deliverables" [ngClass]="{'font-weight-bold': isActive('/deliverables')}">Deliverables</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/internal-categories" [ngClass]="{'font-weight-bold': isActive('/internal-categories')}">Internal Categories</a>
          </li>
        </ul>
      </div>
      <div *ngIf="env.enableImplementationDetailFeature" class="dropdown-divider"></div>
      <div *ngIf="env.enableImplementationDetailFeature" class="list-group-item border-end-0 rounded-0 d-inline-block text-truncate">
        <a class="nav-link" routerLink="/calendar-events">
          <i class="bi bi-calendar"></i> Calendar
        </a>
      </div>
      <div class="dropdown-divider"></div>
      <div class="list-group-item border-end-0 rounded-0 d-inline-block text-truncate">
        <a class="nav-link" routerLink="/support">
          <i class="bi bi-life-preserver"></i> Support
        </a>
      </div>
      <div class="dropdown-divider"></div>
      <div class="list-group-item border-end-0 rounded-0 d-inline-block text-truncate">
        <ul class="nav flex-column">
          <li class="nav-item" *ngIf="!loginDisplay">
            <button type="button" class="btn btn-primary" (click)="loginRedirect()">Log In</button>
          </li>
          <li class="nav-item" *ngIf="loginDisplay">
            <a class="nav-link" (click)="logout()">Log Out</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  