import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unique',
})
export class UniquePipe implements PipeTransform {
  transform(value: any[], property: string): any[] {
    const uniqueValues = [];
    const uniqueMap = new Map();

    for (const item of value) {
      const propValue = item[property];
      if (!uniqueMap.has(propValue)) {
        uniqueMap.set(propValue, true);
        uniqueValues.push(item);
      }
    }

    return uniqueValues;
  }
}
