import { Component, OnInit } from '@angular/core';
import { IMultiSelectSettings } from 'ngx-bootstrap-multiselect';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'src/app/services/data.service';



@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent implements OnInit {

  reportBase: any;

  implementations: any[] = [];

  implementationsModel: number[];
  rolesModel: number[];
  deliverablesModel: number[];
  dateRange: any[];


  settings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: 'checkboxes',
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 3,
    displayAllSelectedText: true,
    showCheckAll: true,
    showUncheckAll: true
  };
  isLoggedIn: boolean;

  constructor(private dataService: DataService, private spinnerService: NgxSpinnerService, private toastr: ToastrService ) {}

  ngOnInit(): void {
    // this.spinnerService.show();
    // this.dataService.getReportBase().subscribe(results =>
    //   {
    //     this.reportBase = results
    //     this.spinnerService.hide();
    //   }, err=> this.handleError(err));
  }

  handleError(err) {
    this.spinnerService.hide();
    alert(err.error);
  }

  handleIFrameError() {
    this.handleError({ error: 'You must be logged into Qlik to use this feature.'});
  }

  // logModel(imp: any) {
  //   console.log(this.implementationsModel);
  //   console.log(imp);
  // }

  generateReport() {
    // console.log(this.dateRange);
    let request: ReportRequestDTO = {
      StartDateTime: this.dateRange[0],
      EndDateTime: this.dateRange[1],
      ImplementationIDs: this.implementationsModel,
      ReportType: ''
    };

    this.dataService.getReportCsv(JSON.stringify(request)).subscribe();

  }

  reloadPage() {
    window.location.reload();

    this.isLoggedIn = true;
  }

}

export interface ReportRequestDTO
{
  StartDateTime: Date,
  EndDateTime: Date,
  ImplementationIDs: number[],
  ReportType: string
}

// public class ReportRequestDTO
//     {
//         public DateTime StartDateTime { get; set; }
//         public DateTime EndDateTime { get; set; }

//         public List<int> ImplementationIDs { get; set; }

//         public string ReportType { get; set; }
//     }
