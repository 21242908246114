<div class="modal-header">
  Edit Time Entry
</div>
<div class="modal-body">

<div class="container">
  <form [formGroup]="angForm" (ngSubmit)="onSubmit()" novalidate>
    <div class="form-row align-items-center">
      <div class="col-auto">
        <label class="sr-only" for="implementation">Implementation:</label>
        <div class="d-flex align-items-center">
          <select formControlName="implementationName" name="implementationName"
            [ngClass]="{ 'form-control': true, 'is-invalid': isInvalid('implementationName') }">
            <option value="" disabled>Select an implementation...</option>
            <option *ngFor="let implementationEstimate of config?.ImplementationEstimates"
              [ngValue]="implementationEstimate.ImplementationEstimateID">{{ implementationEstimate.SFImplementationName }}</option>
          </select>
          <input class="form-check-input mx-2" name="internalTimeCheckbox" type="checkbox"
            formControlName="isInternalTime">
          <label class="form-check-label" for="internalTimeCheckbox" (click)="angForm.get('isInternalTime').setValue(!angForm.get('isInternalTime').value)">Internal Time</label>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="implementation && implementation.ConfirmMessage">
      <label for="ConfirmMessage" class="text-danger">Confirmation Message:</label>
      <div class="col-auto d-flex align-items-center" style="width:100%">
        <textarea name="ConfirmMessage" class="form-control text-danger" style="width:100%" readonly>{{ implementation.ConfirmMessage }}</textarea>
      </div>
    </div>
    <div class="row">
      <p class="m-1" *ngIf="implementation">
        Start Date: {{ implementation?.ImplementationStartDate | date: 'MM/dd/yyyy'}} &nbsp; | &nbsp;
        End Date: {{ implementation?.ImplementationEndDate | date: 'MM/dd/yyyy'}} &nbsp; | &nbsp;
        <a [href]="implementation?.ImplementationLink" target="_blank">View in Salesforce</a> &nbsp;| &nbsp;
        <a [href]="'/implementations/'+implementation?.ImplementationEstimateID" target="_blank">View Overrides</a>
      </p>
    </div>

    <div class="row">
      <div class="form-group">
        <label for="implementationDeliverable">Deliverable:</label>
        <select [ngClass]="{ 'form-control': true, 'is-invalid': isInvalid('implementationDeliverable') }"
          formControlName="implementationDeliverable" name="implementationDeliverable" required autocomplete="off">
          <option value="" disabled>Select a deliverable...</option>
          <option *ngFor="let deliverable of config?.Deliverables" [ngValue]="deliverable.DeliverableID">
            {{deliverable.Name}}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="implementationRole">Role:</label>
        <select [ngClass]="{ 'form-control': true, 'is-invalid': isInvalid('implementationRole')}"
          formControlName="implementationRole" name="implementationRole" required autocomplete="off">
          <option value="" disabled>Select a role...</option>
          <option *ngFor="let role of config?.Roles" [value]="role.RoleID">{{role.Name}}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <div class="form-group">
          <label for="internalTimeCategory">Internal Time Category:</label>
          <select [ngClass]="{ 'form-control': true, 'is-invalid': isInvalid('internalTimeCategory') }"
            name="internalTimeCategory" formControlName="internalTimeCategory">
            <option value="">Select a category...</option>
            <option *ngFor="let category of config?.InternalTimeCategories"
              [ngValue]="category.InternalTimeCategoryID">{{ category.Name}}</option>
          </select>
        </div>
      </div>
      <div class="col-8">
        <div class="form-group">
          <label for="internalTimeSubcategory">Internal Time Subcategory:</label>
          <select [ngClass]="{ 'form-control': true, 'is-invalid': isInvalid('internalTimeSubcategory') }"
            name="internalTimeSubcategory" formControlName="internalTimeSubcategory">
            <option *ngIf="!angForm.get('internalTimeCategory').value" [value]="''">Select a category first...</option>
            <option *ngIf="angForm.get('internalTimeCategory').value" [value]="''">Select a subcategory...</option>
            <option *ngFor="let subcategory of filteredSubcateogries"
              [ngValue]="subcategory.InternalTimeSubcategoryID">{{ subcategory.Name}}</option>
          </select>
        </div>
      </div>


    </div>
    <div class="row">
      <div class="col-8">
        <div class="form-group">
          <label for="entryDate">Entry Date:</label>
          <input formControlName="entryDate" name="entryDate" type="text" placeholder="''" bsDatepicker
            [ngClass]="{ 'form-control': true, 'is-invalid': isInvalid('entryDate') }" required autocomplete="off">
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label for="timeSpent">Hours:</label>
          <input [ngClass]="{ 'form-control': true, 'is-invalid': isInvalid('timeSpent')}"
            formControlName="timeSpent" name="timeSpent" type="number" min="0.000001" required autocomplete="off">
        </div>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col-12">
        <div class="form-group">
          <label for="title">Title:</label>
          <input [ngClass]="{ 'form-control': true, 'is-invalid': isInvalid('title')}" type="text"
            placeholder="Title" formControlName="title" name="title" required autocomplete="off">
        </div>
        <div class="form-group">
          <label for="note">Note:</label>
          <textarea class="form-control" name="note" formControlName="note" placeholder="Add a note..."></textarea>
        </div>

      </div>
    </div>
    <!-- <div class="row mt-1">
      <div class="col-6 pull-left">
        <div class="form-check">
          <label class="form-check-label" for="createAnother" (click)="angForm.get('createAnother').setValue(!angForm.get('createAnother').value)">Carry over selected values to next entry.</label>
          <input class="form-check-input" name="createAnother" type="checkbox" formControlName="createAnother">
        </div>
      </div>
      <div class="col-6">
        <button class="btn btn-warning" style="float:right;" type="button" (click)="logForm()">Log Form</button>
        <button class="btn btn-primary" style="float: right;" type="submit">Submit</button>
      </div>
    </div> -->
    <div class="modal-footer">
      <button type="button" class="btn btn-primary pull-right" type="submit">Submit</button>
      <button type="button" class="btn btn-secondary pull-right" aria-label="Close" name="Close" (click)="bsModalRef?.hide()"
       >Close</button>
    </div>
  </form>
</div>
</div>

