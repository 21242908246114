<form [formGroup]="attachmentForm" (ngSubmit)="doSave()">
  <div class="modal-header">
    <h3>Add attachment to <b>{{ ImplementationEstimate?.SFImplementationName }}</b></h3>
  </div>

  <div class="modal-body">
    <div class="form-group row align-items-center mb-3">
      <label for="fileUpload" class="col-sm-3 col-form-label">Select a file</label>
      <div class="col-sm-9">
        <input
          type="file"
          id="fileUpload"
          (change)="onFileSelected($event)"
          class="form-control"
          [class.is-invalid]="attachmentForm.get('file')?.invalid && attachmentForm.get('file')?.touched"
        />
        <div *ngIf="attachmentForm.get('file')?.invalid && attachmentForm.get('file')?.touched" class="invalid-feedback">
          File is required.
        </div>
      </div>
    </div>

    <div class="form-group row align-items-center mb-3">
      <label class="col-sm-3 col-form-label">Attachment Type</label>
      <div class="col-sm-9">
        <select
          formControlName="AttachmentTypeID"
          class="form-control"
          [class.is-invalid]="attachmentForm.get('AttachmentTypeID')?.invalid && attachmentForm.get('AttachmentTypeID')?.touched"
        >
          <option value="0" disabled>Select type</option>
          <option *ngFor="let attachmentType of attachmentTypes" [value]="attachmentType.AttachmentTypeID">{{attachmentType.Description}}</option>
        </select>
        <div *ngIf="attachmentForm.get('AttachmentTypeID')?.invalid && attachmentForm.get('AttachmentTypeID')?.touched" class="invalid-feedback">
          Attachment type is required.
        </div>
      </div>
    </div>

    <div class="form-group row align-items-center mb-3">
      <label for="desc" class="col-sm-3 col-form-label">Description</label>
      <div class="col-sm-9">
        <textarea formControlName="description" id="desc" class="form-control"></textarea>
      </div>
    </div>

    <div *ngIf="file" class="mt-3">
      <p><strong>File Name:</strong> {{ file.name }}</p>
      <p><strong>File Size:</strong> {{ file.size | number }} bytes</p>
      <p><strong>File Type:</strong> {{ file.type }}</p>
    </div>
  </div>

  <div class="modal-footer">
    <div class="d-flex justify-content-end w-100">
      <button
        type="submit"
        class="btn btn-primary me-2"
        [disabled]="attachmentForm.invalid"
      >
        Save
      </button>
      <button type="button" class="btn btn-danger" (click)="closeModal()">Close</button>
    </div>
  </div>
</form>
