import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { faSalesforce } from '@fortawesome/free-brands-svg-icons';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import {
  DeliverableData,
  RoleData,
  TemplateEstimate,
  TemplateMonthData,
} from 'src/app/objects/Interfaces';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-template-detail',
  templateUrl: './template-detail.component.html',
  styleUrls: ['./template-detail.component.scss'],
})
export class TemplateDetailComponent implements OnInit {
  params: any;
  faTrash = faTrash;
  faPen = faPen;
  faSalesforce = faSalesforce;
  template: any;
  pivotData: any[] = [];
  editedItems: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private toastr: ToastrService,
    private spinnerService: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.spinnerService.show();
      this.dataService.getTemplate(params['Id']).subscribe(
        (x) => {
          this.template = x;
          this.doPivot();
        },
        (err) => this.handleError(err)
      );
    });
  }

  doPivot() {
    this.pivotData = [];

    this.template.Roles.forEach((role) => {
      let roleParent: RoleData = {
        Role: role,
        Deliverables: [],
      };

      let estimatesForRole = this.template.Template.TemplateEstimates.filter(
        (y) => y.RoleID == role.RoleID
      );

      this.template.Deliverables.forEach((d) => {
        let deliverableData: DeliverableData = {
          Deliverable: d,
          Months: [],
        };

        let devOverrides = estimatesForRole.filter(
          (y) => y.DeliverableID == d.DeliverableID
        );

        this.template.MonthColumns.forEach((month) => {
          let monthData: TemplateMonthData = {
            Month: month,
            Estimate: null,
          };

          let estimate = devOverrides.find(
            (x) => x.MonthStart == month.MonthIndex
          );

          if (!estimate) {
            monthData.Estimate = {
              DeliverableID: d.DeliverableID,
              EstimatedHours: null,
              TemplateID: this.template.Template.TemplateID,
              TemplateEstimateID: 0,
              MonthStart: month.MonthIndex,
              RoleID: role.RoleID,
            };
          } else {
            monthData.Estimate = estimate;
          }

          deliverableData.Months.push(monthData);
        }); //end month loop

        roleParent.Deliverables.push(deliverableData);
      });

      this.pivotData.push(roleParent);

    }); //end role loop

    this.applyEdits();

    this.spinnerService.hide();
  }

  addMonth() {
    let max = this.template.MonthColumns.reduce(function (prev, current) {
      if (+current.MonthIndex > +prev.MonthIndex) {
        return current;
      } else {
        return prev;
      }
    });

    let monthColumn = {
      MonthIndex: max.MonthIndex + 1,
      MonthName: 'Month ' + max.MonthIndex,
    };

    this.spinnerService.show();
    this.template.MonthColumns.push(monthColumn);
    this.doPivot(); //push the change to the moddel.

  }

  editHours(estimate: TemplateEstimate) {
    const alreadyExists = this.editedItems.findIndex(x => x.DeliverableID == estimate.DeliverableID &&  x.RoleID == estimate.RoleID && x.MonthStart == estimate.MonthStart);

    if (alreadyExists > -1) {
      this.editedItems.splice(alreadyExists, 1);
    }

    this.editedItems.push(estimate);

  }

  saveChanges() {
    this.spinnerService.show();

    console.log(this.editedItems);

    this.dataService.saveEstimates(this.template.Template.TemplateID, this.editedItems).subscribe(result => {
      this.template = result;
      this.editedItems = null;
      this.doPivot();
    }, err => this.handleError(err));

    this.spinnerService.hide();
    this.toastr.success('Changes saved.')
  }

  handleError(err) {
    alert(err.message);
    //alert(err.error);
  }

  applyEdits() {
    this.editedItems?.forEach(editedItem => {

      const role = this.pivotData.find((r) => r.Role.RoleID === editedItem.RoleID);

      if (role) {
        const deliverable = role.Deliverables.find(
          (d) => d.Deliverable.DeliverableID === editedItem.DeliverableID
        );

        if (deliverable) {
          const monthIndex = editedItem.MonthStart -1;
          const month = deliverable.Months[monthIndex];
          if (month) {
            // Update the estimate
            month.Estimate = editedItem;
          }
        }
      }
    });
  }
}
