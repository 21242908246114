import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SfObject } from 'src/app/components/sf-typeahead/sf-typeahead.component';
import { MatColumn } from 'src/app/grid/material-grid/material-grid.component';
import { CalendarEventService } from 'src/app/services/calendar-event.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-calendar-events-list',
  templateUrl: './calendar-events-list.component.html',
  styleUrls: ['./calendar-events-list.component.scss'],
})
export class CalendarEventsListComponent implements OnInit {

  
  calendarEvents: any;
  data: any[] = [];
  columns: any[] = [];
  isLoading: boolean = false;
  @Input() implementation: any;
  modalRef?: BsModalRef;

  modalData: {
    previewModalHtml: SafeHtml;
    previewModalSubject: string;
    selectedImplementation: SfObject | null;
    selectedCalendarEvent: any;
    selectedOpportunity: SfObject | null;
  } = {
    previewModalHtml: '',
    previewModalSubject: '',
    selectedImplementation: null,
    selectedCalendarEvent: null,
    selectedOpportunity: null,
  };

  @ViewChild('previewModalTemplate') previewModalTemplate: any;


  constructor(
    private utilityService: UtilityService,
    private calendarService: CalendarEventService,
    private sanitizer: DomSanitizer,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.loadCalendarEvents();
  }

  loadCalendarEvents() {
    this.isLoading = true;

    this.calendarService.getAssignedEvents(this.implementation.SFImplementationID).subscribe(
      (result) => {
        this.calendarEvents = result;
        this.data = this.calendarEvents.Data;
        this.columns = this.utilityService.getGridColumns(this.calendarEvents.GridConfig.GridColumns);
        this.isLoading = false;
      },
      (err) => {
        alert(err);
      }
    );
  }

  showPreviewModal(event: { column: MatColumn; rowData: any }) {
    const content = JSON.parse(event.rowData.CalendarJson).Body?.Content;
    
    this.modalData.previewModalHtml =
      this.sanitizer.bypassSecurityTrustHtml(content);
    this.modalRef = this.modalService.show(this.previewModalTemplate, {
      class: 'modal-lg',
    });
  }

}
