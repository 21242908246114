import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment.development';

const api = "https://"

@Injectable({
  providedIn: 'root',
})
export class TestService {
  constructor(protected http: HttpClient) {}

  getWeather(): Observable<any> {
    return this.http.get(`https://localhost:7130/api/WeatherForecast`);
  }
}
