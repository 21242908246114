import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-template-detail-tab-view',
  templateUrl: './template-detail-tab-view.component.html',
  styleUrls: ['./template-detail-tab-view.component.scss']
})
export class TemplateDetailTabViewComponent implements OnInit {

  params: any;
  template: any;
  estimatesByRole: any[] = [];
  faTrash = faTrash;
  faPen = faPen;
  editing: boolean = false;

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.dataService.getTemplate(params['Id']).subscribe((x) => {
        this.template = x;
          this.template.Roles.forEach(role => {
          this.estimatesByRole.push({ Role: role, Estimates:  this.template.Template.TemplateEstimates.filter((x) => x.RoleID == role.RoleID)} );
        });
      });
    });
  }

  sortByMonth() {
    this.estimatesByRole.sort((a, b) => (a.MonthStart > b.MonthStart) ? 1 : -1)
  }
}
