<div class="container-fluid">
    <app-material-grid [data]="calendarEvents?.Data" [columns]="columns" [loading]="isLoading" 
        (hyperlinkClicked)="showPreviewModal($event)"></app-material-grid>
</div>

<ng-template #previewModalTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{ modalData.previewModalSubject }}</h4>
        <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <p [innerHTML]="modalData.previewModalHtml"></p>
        </div>
    </div>
</ng-template>