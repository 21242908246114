import { Component, OnInit, TemplateRef } from '@angular/core';
import { faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AzureadService } from 'src/app/services/azuread.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-deliverables-editor',
  templateUrl: './deliverables-editor.component.html',
  styleUrls: ['./deliverables-editor.component.scss'],
})
export class DeliverablesEditorComponent implements OnInit {
  modalRef?: BsModalRef;
  deliverables: any;
  selectedDeliverable: any;

  faPen = faPen;
  faPlus = faPlus;
  faTrash = faTrash;

  constructor(
    private dataService: DataService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private azuread: AzureadService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.spinnerService.show();
    this.dataService.getDeliverables().subscribe(
      (y) => { this.deliverables = y; this.spinnerService.hide(); },
      (err) => console.error(err)
    );
  }

  showModal(template: TemplateRef<any>, deliverable: any) {
    this.selectedDeliverable = Object.assign({}, deliverable);
    this.modalRef = this.modalService.show(template, { class: 'modal-md' });
  }

  saveDeliverable(d: any) {
    this.spinnerService.show();

    this.dataService.saveDeliverable(d).subscribe(
      (y) => {
        this.toastr.success(d.Name, 'Record Saved', {timeOut: 5000});
        this.deliverables = y;
        this.spinnerService.hide();
      },
      (err) => console.error(err)
    );

    this.modalRef.hide();
    this.selectedDeliverable = null;
  }

  showConfirmModal(template: TemplateRef<any>, d: any) {
    this.selectedDeliverable = Object.assign({}, d);
    this.modalRef = this.modalService.show(template, { class: 'modal-sm'});
  }

  doDelete(d: any) {
    d.IsDeleted = true;

    this.saveDeliverable(d);
  }
}
