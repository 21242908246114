<div class="container-fluid">
  <div class="row">
    <h3>Editing Overrides</h3>
    <div class="col-lg-4">
      <div class="card w-100" style="margin-left:15px">
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <b>Name:</b>&nbsp;
            <a [href]="sfLink" target="_blank">{{ estimate?.ImplementationEstimate.SFImplementationName }}</a>
          </li>
          <li class="list-group-item">
            <b>Template:</b> {{ estimate?.ImplementationEstimate.Template.Name }}
          </li>
          <li class="list-group-item">
            <b>Start Date:</b> {{ estimate?.ImplementationEstimate.ImplementationStartDate | date: 'MM/dd/yyyy' }}
          </li>
          <li class="list-group-item">
            <b>End Date:</b> {{ estimate?.ImplementationEstimate.ImplementationEndDate | date: 'MM/dd/yyyy' }}
          </li>
        </ul>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="card" style="margin-left:15px;">
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <b>Estimated Hours:</b>&nbsp; {{ estimate?.ImplementationEstimate.TotalHours }}
            
          </li>
          <li class="list-group-item">
            <div class="card-body">
              <label>Confirm Message <fa-icon class="text-primary" [icon]="faQuestionCircle"
                  tooltip="When present, team members receive a message prior to saving a time entry."></fa-icon></label>
              <textarea class="form-control" [(ngModel)]="estimate?.ImplementationEstimate.ConfirmMessage"></textarea>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <hr>
  <!-- <div class="row">
  <div class="col-12"> <button class="btn btn-primary" style="float:right" (click)="saveChanges()">Save Changes</button></div>
</div> -->
  <div class="row mt-3">
    <div class="col-lg-12">
      <div>
        <button class="btn btn-primary m-1" (click)="saveChanges()">Save Changes</button>
        <button class="btn btn-danger m-1" (click)="showDeleteAssignmentModal(deleteTemplate, estimate?.ImplementationEstimate.ImplementationEstimateID)">Delete Implementation</button>
        <button class="btn btn-secondary m-1" (click)="addMonth()">Add Month</button>
      </div>
      <hr>
      <tabset>
        <tab *ngFor="let role of estimate?.Roles" [heading]="role.Name">
          <table class="table table-responsive table-bordered table-striped mb-1"
            style="overflow-y: scroll; overflow-x: scroll; max-width: 100%;">
            <thead style="position: sticky">
              <th>Deliverable</th>
              <th class="text-center" *ngFor="let monthColumn of estimate?.MonthColumns;">{{ monthColumn.MonthIndex }}
              </th>
            </thead>
            <tbody>
              <ng-container *ngFor="let x of (pivotData | filterByRole: role.RoleID)">
                <tr *ngFor="let deliverable of x.Deliverables; let i = index">
                  <td style="position: sticky">{{ deliverable.Deliverable.Name }} </td>
                  <ng-container *ngFor="let month of deliverable.Months">
                    <td style="min-width:2em"><input class="form-control" type="number"
                        [(ngModel)]="month.Override.EstimatedHours" (ngModelChange)="editHours(month.Override)" /></td>
                  </ng-container>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </tab>
      </tabset>
    </div>
  </div>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#ffeb3b" type="pacman" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
</div>

<ng-template #deleteTemplate>
  <div class="modal-body text-center">
    <p><b><fa-icon [icon]="faTriangleExclamation" class="text-danger"></fa-icon>&nbsp; Do you want archive this implementation?</b></p>
    <p>This action will archive the implementation through soft-delete. Time Entries will still be present.</p>
    <button type="button" class="btn btn-danger m-1" (click)="deleteImplementation()">Yes</button>
    <button type="button" class="btn btn-primary m-1" (click)="modalRef?.hide()">No</button>
  </div>
</ng-template>

