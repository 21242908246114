<div class="container">
  <div class="row justify-content-center mt-5">

    <div class="col-10 offset-1">
      <div class="row">
        <h4>Time Tracker:</h4>
        <ul class="list-group">
          <a class="list-group-item list-group-item-action" target="_blank"
            href="https://o3insight.atlassian.net/servicedesk/customer/portal/1/group/4/create/12">Bugs & General
            Issues</a>
          <a class="list-group-item list-group-item-action" target="_blank"
            href="https://o3insight.atlassian.net/servicedesk/customer/portal/1/group/1/create/1">Feature Requests</a>
          <a class="list-group-item list-group-item-action" target="_blank"
            href="https://o3insight.atlassian.net/servicedesk/customer/portal/1/group/3/create/18">Request Permissions
            for Templates,
            Internal Categories, Deliverables, etc.</a>
        </ul>
      </div>

      <div class="row mt-5">

        <h4>General Support:</h4>
        <ul class="list-group">
          <a class="list-group-item list-group-item-action" target="_blank"
            href="https://o3insight.atlassian.net/servicedesk/customer/portal/1/group/5/create/24">Offboard Client /
            Environment</a>
          <a class="list-group-item list-group-item-action" target="_blank"
            href="https://o3insight.atlassian.net/servicedesk/customer/portal/1/group/4/create/22">GDPR - Data Removal
            from O3 Client Environments "Right to be Forgotten"</a>
          <a class="list-group-item list-group-item-action" target="_blank"
            href="https://o3insight.atlassian.net/servicedesk/customer/portal/1/group/4/create/23">Security Incidents
            (Data Breaches, etc.)</a>
          <a class="list-group-item list-group-item-action" target="_blank"
            href="https://o3insight.atlassian.net/servicedesk/customer/portal/1/group/4/create/8">Purchase Software</a>

        </ul>
      </div>
    </div>
  </div>
</div>
