import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sf-typeahead',
  templateUrl: './sf-typeahead.component.html',
  styleUrls: ['./sf-typeahead.component.scss']
})
export class SfTypeaheadComponent {
  @Input() dataSource: any[];
  @Input() selectedObject: SfObject | null = null;
  @Input() placeholderText: string = 'Select...';
  @Output() selectedObjectChange = new EventEmitter<SfObject | null>();
  @Output() objectSelected = new EventEmitter<SfObject>();

  selectedObjectName: string;
  sfObjects: SfObject[] = [];


  ngOnInit(): void {
    this.sfObjects = this.mapToKeyValue(this.dataSource);
  }

  mapToKeyValue(data: any[]): SfObject[] {
    return data.map(item => ({
      id: item.id,
      name: item.name
    }));
  }

  onSfObjectSelect(event: any): void {
    this.selectedObject = event.item;
    this.selectedObjectName = event.item.name;
    this.selectedObjectChange.emit(this.selectedObject);
    this.objectSelected.emit(this.selectedObject);
  }
}

//All sf objects have an id and name.
export interface SfObject {
  id: string;
  name: string;
}