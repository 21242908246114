import { ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faSalesforce } from '@fortawesome/free-brands-svg-icons';
import { faInfoCircle, faDollar, faUser, faCalendar, faNoteSticky, faUpload, faFile, faWarning, faFloppyDisk, faChartArea, faPersonBreastfeeding, faTable } from '@fortawesome/free-solid-svg-icons';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil, finalize } from 'rxjs';
import { AddAttachmentModalComponent } from 'src/app/attachments/add-attachment-modal/add-attachment-modal.component';
import { AttachmentsListComponent } from 'src/app/attachments/attachments-list/attachments-list.component';
import { NoteEntryModalComponent } from 'src/app/notes/note-entry-modal/note-entry-modal.component';
import { NotesListComponent } from 'src/app/notes/notes-list/notes-list.component';
import { DataService } from 'src/app/services/data.service';
import { SalesforceService } from 'src/app/services/salesforce.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-implementation-detail-sf-v2',
  templateUrl: './implementation-detail-sf-v2.component.html',
  styleUrls: ['./implementation-detail-sf-v2.component.scss']
})
export class ImplementationDetailSfV2Component {
  @Input() sfId: string;

  isEdited = false;
  sfLink: string;
  sfImplementation: any;
  O3Contacts: any[];
  data: any;
  phasePickListValues: any[] = [];
  implementationForm: FormGroup;
  private unsubscribe$ = new Subject<void>();

  faInfo = faInfoCircle;
  faDollar = faDollar;
  faUser = faUser;
  faSalesforce = faSalesforce;
  faCalendar = faCalendar;
  faWarning = faWarning;
  faFloppyDisk = faFloppyDisk;
  faGrid = faChartArea;
  faNoteSticky = faNoteSticky;
  faUpload = faUpload;
  faFile = faFile;
  faTable = faTable;
  
  isInitialLoad: boolean = true;
  modalRef?: BsModalRef;
  implementation: any;
  activeTab: string = 'project-info'
  activeTabs: Set<string> = new Set();

  @ViewChild('notesList') notesList: NotesListComponent;
  @ViewChild('alertNotesList') alertNotesList: NotesListComponent;
  @ViewChild('attachmentsList') attachmentsList: AttachmentsListComponent;

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private salesforceService: SalesforceService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    public utilityService: UtilityService,
    private modalService: BsModalService,
    private fb: FormBuilder,
    private bsDatepickerConfig: BsDatepickerConfig,
    private cdr: ChangeDetectorRef // Inject ChangeDetectorRef
  ) {
    this.bsDatepickerConfig.useUtc = true;
    this.bsDatepickerConfig.dateInputFormat = 'YYYY-MM-DD';
  }

  ngOnInit(): void {
    this.initializeForm();
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.loadData(params['Id']);
    });

    this.implementationForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (!this.isInitialLoad) {
          // Check the flag

          this.isEdited = true;
          this.cdr.detectChanges();
          // this.isEdited = true;
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onTabSelect(tabTitle: string) {
    this.activeTab = tabTitle;
    
    if (!this.activeTabs.has(tabTitle)) {
      this.activeTabs.add(tabTitle);

      this.spinnerService.show();
      setTimeout(() => {this.spinnerService.hide();}, 500);
    }
    

    
  }

  isTabActive(tabId: string): boolean {
    return this.activeTabs.has(tabId);
  }

  initializeForm() {
    this.implementationForm = this.fb.group({
      phase__c: [''],
      number__c: [''],
      implementation_Start_Date__c: ['', Validators.required],
      implementation_End_Date__c: ['', Validators.required],
      project_Manager_Contact__c: ['', Validators.required],
      o3_SME_Contact__c: [''],
      data_Consultant_Contact__c: [''],
      aWP_Consultant_Contact__c: [''],
      trainer_Contact__c: [''],
      mMR__c: [''],
      hour_Sold__c: [''],
      pO_Date__c: [''],
      kO_Date__c: [''],
      actual_Go_Live_Date__c: [''],
      planned_Go_Live_Date__c: [''],
    });
  }

  loadData(id: string) {
    this.spinnerService.show();
    this.dataService
      .getImplementationEstimate(id)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe(
        (response) => {

          this.sfLink = this.salesforceService.getSFImplementationLink(
            response.ImplementationEstimate.SFImplementationID
          );
          this.sfImplementation = response.SalesforceImplementation;
          this.implementation = response.ImplementationEstimate;

          this.O3Contacts = response.O3Contacts;
          this.data = response;

          this.implementationForm.patchValue(this.sfImplementation);

          const phasePickList = this.data.SalesforcePicklists.find(
            (item) => item.Name === 'Phase__c'
          );
          this.phasePickListValues = phasePickList
            ? phasePickList.PickListValues
            : [];
          this.isInitialLoad = false;
        },
        (error) => {
          console.error('Error loading data:', error);
          this.toastr.error('Failed to load data');
        }
      );
  }

  doSave() {
    if (this.implementationForm.valid) {
      Object.assign(this.sfImplementation, this.implementationForm.value);
      console.log(this.implementationForm.value.pO_Date__c);

      this.salesforceService
        .saveSfImplementation(this.sfImplementation)
        .subscribe(
          (response) => {
            this.sfImplementation = response;
            this.implementationForm.patchValue(response);

            this.toastr.success('Data saved successfully!');
          },
          (error) => {
            console.error('Error saving data:', error);
            this.toastr.error('Failed to save data');
          }
        );
    } else {
      this.toastr.error('Form is invalid. Please check the fields.');
    }
  }

  openCreateNoteModal() {
    const initialState = {
      ImplementationEstimate: this.implementation,
      NoteTypeID: null,
      NoteType: null,
    };

    this.modalRef = this.modalService.show(NoteEntryModalComponent, {
      class: 'modal-xl',
      initialState: initialState,
    });

    this.modalRef.onHide.subscribe((x) => {
      //todo: modify so only on save.
      this.notesList.refresh(); 
      this.alertNotesList.refresh();
    });
  }

  openAttachmentModal() {
    const initialState = {
      ImplementationEstimate: this.implementation,
      associationType: 'implementation'
    };

    this.modalRef = this.modalService.show(AddAttachmentModalComponent, {
      class: 'modal-lg',
      initialState: initialState,
    });

    this.modalRef.onHide.subscribe(x => {
      this.attachmentsList.refresh();
    })
  }
}
