<div class="container-fluid">
    <app-material-grid [data]="attachments?.Data" [columns]="columns" (hyperlinkClicked)="downloadAttachment($event)" [showDeleteButton]="true" [loading]="isLoading"></app-material-grid>
</div>

<!-- Modal confirmation dialog -->
<ng-template #confirmDeleteTemplate>
    <div class="modal-header">
        <h5 class="modal-title">Confirm Delete</h5>
        <button type="button" class="close" (click)="modalRef?.hide()">&times;</button>
    </div>
    <div class="modal-body">
        Are you sure you want to delete this attachment?
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modalRef?.hide()">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="confirmDelete(modalRef.content.attachmentID)">
            Confirm
        </button>
    </div>
</ng-template>