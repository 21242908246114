import { HttpClient } from '@angular/common/http';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { faInfoCircle, faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DataService } from 'src/app/services/data.service';
import { SalesforceService } from 'src/app/services/salesforce.service';
import { ImplementationAssignmentModalComponent } from '../implementation-assignment-modal/implementation-assignment-modal.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';

export interface ITemplate {
  Id: number;
  Product: string;
  Name: string;
}

@Component({
  selector: 'app-template-assignment-list',
  templateUrl: './template-assignment-list.component.html',
  styleUrls: ['./template-assignment-list.component.scss'],
})
export class TemplateAssignmentListComponent implements OnInit {
  constructor(
    private dataService: DataService,
    private modalService: BsModalService,
    private salesforceService: SalesforceService,
    private spinnerService: NgxSpinnerService
  ) {}

  templates: any;
  implementations: any;
  selectedImplementation: any;
  modalRef?: BsModalRef;
  faPen: any = faPen;
  faPlus: any = faPlus;
  faTrash: any = faTrash;
  faInfoCircle: any = faInfoCircle;

  ngOnInit(): void {
    this.modalService.onHide.subscribe(() => this.loadData());
    this.loadData();
  }

  loadData() {
    this.spinnerService.show();
    this.dataService.getAllTemplates().subscribe(x =>{
      this.templates = x;
      setTimeout(() =>this.spinnerService.hide(), 1000);
    });

    // this.salesforceService.getImplementations().subscribe(x => {
    //   this.implementations = x.Implementations;
    //   setTimeout(() =>this.spinnerService.hide(), 1000);
    // });
  }

  showImplementationAssignmentModal(template: any) {
    const initialState = {
      list: [
        template
      ],
      template: template,
      class: 'modal-lg'
    };

    this.modalRef = this.modalService.show(ImplementationAssignmentModalComponent, initialState);
    this.modalRef.content.template = template;
  }
}
