import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { MatColumn } from '../grid/material-grid/material-grid.component';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
    convertToDate(value: Date | string | null): Date | null {
        if (value) {
          if (typeof value === 'string') {
            // Parse the string with moment, fallback to null if invalid
            const parsedDate = moment(value, 'YYYY-MM-DD', true); // Strict parsing
            return parsedDate.isValid() ? parsedDate.toDate() : null;
          } else if (value instanceof Date) {
            // Strip time from Date object
            const strippedDate = new Date(value.getFullYear(), value.getMonth(), value.getDate());
            return strippedDate;
          }
        }
        return null;
      }

      getGridColumns(columns: any[]) : MatColumn[] {
        const result = columns.filter(
          (col: any) => col.DisplayInGrid
        ).map((col: any) => ({
          key: col.PropertyName,
          header: col.HeaderTitle,
          dataType: col.DataType,
          isHyperlink: col.IsHyperlink,
          isHtml: col.IsHtml
        }));

        return result;
      }
}