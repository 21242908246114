import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AzureadService } from 'src/app/services/azuread.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-categories-modal',
  templateUrl: './categories-modal.component.html',
  styleUrls: ['./categories-modal.component.scss']
})
export class CategoriesModalComponent implements OnInit {

  @Output() onClose: EventEmitter<any> = new EventEmitter();

  categoryName: any;
  selectedCategory: any;

  faPlus = faPlus;

  constructor(
    private dataService: DataService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private azuread: AzureadService,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef
  ) {}

  ngOnInit(): void {
  }

  saveInternalCategory(): void {
    this.dataService.setInternalTimeCategory(this.selectedCategory).subscribe(r => {
      this.onClose.emit();
      this.bsModalRef.hide();
    }, err => {
      alert(err)
    });
  }

  addNewSubcategory(): void {
    let newSubCat = {
      InternalTimeCategoryID: this.selectedCategory.InternalTimeCategoryID,
      InternalTimeSubcategoryID: 0,
      IsBillable: false,
      IsDeleted: false,
      IsLegacy: false
    };

    this.selectedCategory.InternalTimeSubcategories.push(newSubCat);
  }
}
