<div class="modal-header">
  <h4 class="modal-title">Confirmation</h4>
  <button type="button" class="close" aria-label="Close" (click)="onCancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  {{ message }}
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="onConfirm()">Confirm</button>
</div>
