<div class="row mt-3" *ngIf="template">
  <div class="input-group mb-3">
    <span class="input-group-text">Template Name</span>
    <input class="form-control" id="tempname" [(ngModel)]="template.Template.Name" type="text" />
  </div>
  <div class="input-group mb-3">
    <span class="input-group-text">Product Type</span>
    <input class="form-control" id="templateProduct" [(ngModel)]="template.Template.ProductType.Name" type="text" />
  </div>
</div>
<div class="row">
  <div class="col-lg-8">

  </div>
  <div class="col-lg-4">
    <button class="btn btn-primary" style="float: right;" (click)="editing = !editing;">
      Edit Estimates &nbsp;
      <fa-icon [icon]="faPen"></fa-icon>
    </button>
  </div>
</div>
<div class="row" *ngIf="template">
  <tabset>
    <tab *ngFor="let estimates of estimatesByRole" [heading]="estimates.Role.Name">
      <table class="table table-bordered table-striped mb-1" style="overflow-y: scroll; max-height: 600px">
        <thead>
          <tr>
            <th>ID</th>
            <th>Month</th>
            <th>Deliverable</th>
            <!-- <th>Role</th> -->
            <th>Estimated Hours</th>
            <th *ngIf="editing">Actions</th>
          </tr>
        </thead>
        <tbody *ngIf="!editing">
          <tr *ngFor="let estimate of estimates.Estimates">
            <td>{{estimate.TemplateEstimateID}}</td>
            <td>{{estimate.MonthStart}}</td>
            <td>{{estimate.Deliverable.Name}}</td>
            <td>{{estimate.EstimatedHours}}</td>
            <!-- <td>
              <fa-icon [icon]="faTrash" style="cursor: pointer; margin-right: 3px"></fa-icon>
              <fa-icon [icon]="faPen" style="cursor: pointer;"></fa-icon>
            </td> -->
          </tr>
        </tbody>
        <tbody *ngIf="editing">
          <tr *ngFor="let estimate of estimates.Estimates">
            <td>{{estimate.TemplateEstimateID}}</td>
            <td><input type="number" class="form-control" min="1" max="24" [(ngModel)]="estimate.MonthStart"> </td>
            <td>
              <div class="btn-group" dropdown>
                <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                        aria-controls="dropdown-basic">
                  {{ estimate.Deliverable.Name }} <span class="caret"></span>
                </button>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu"
                    role="menu" aria-labelledby="button-basic">
                  <li *ngFor="let delivable of template.Deliverables" role="menuitem">
                    <a class="dropdown-item" (click)="estimate.Deliverable = delivable">{{delivable.Name}}</a>
                  </li>
                </ul>
              </div>
            </td>
            <td>estimated hrs dropdown</td>
            <td><fa-icon [icon]="faTrash" style="cursor: pointer; margin-right: 3px"></fa-icon></td>
         </tr>
        </tbody>
      </table>
    </tab>
  </tabset>
</div>


  <div class="row mt-3">
    <div class="col-12 pull-right">
      <button class="btn btn-primary" style="float: right;">Add</button>
    </div>
  </div>



<div class="row mt-5">
  <div class="col-8"></div>
  <div class="col-4">
    <button class="btn btn-primary" style="float: right;">Save Changes</button>
  </div>
</div>
