import { Component } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AddAttachmentModalComponent } from 'src/app/attachments/add-attachment-modal/add-attachment-modal.component';
import { NotesService } from 'src/app/services/notes.service';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { AttachmentService } from 'src/app/services/attachment.service';

@Component({
  selector: 'app-note-entry-modal',
  templateUrl: './note-entry-modal.component.html',
  styleUrls: ['./note-entry-modal.component.scss'],
})
export class NoteEntryModalComponent {
  ImplementationEstimate?: any;
  NoteTypeID?: number;
  NoteType?: any;

  showNoteType: boolean = true;
  showPriorities: boolean = false;
  showTitle: boolean = false;

  isLoading: boolean = false;

  priorities: any[] = [];

  attachments: any[] = [];

  noteDto: any;
  isEdit: boolean = false;
  editorInstance: any;
  plainText: string;

  faTrash = faTrash;

  modules: {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote'],

      // [{'header': 1}, {'header': 2}],               // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction

      // [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
      // [{'header': [1, 2, 3, 4, 5, 6, false]}],

      // [{'color': []}, {'background': []}],          // dropdown with defaults from theme
      // [{'font': []}],
      [{ align: [] }],

      ['clean'], // remove formatting button

      // ['link', 'image', 'video',]                   // link and image, video
      ['link'] // link
    ];
  };
  noteForm: FormGroup;
  modalRef: BsModalRef;

  constructor(
    public bsModalRef: BsModalRef,
    private notesService: NotesService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private toasterService: ToastrService,
    private attachmentService: AttachmentService
  ) {}

  ngOnInit(): void {
    this.noteForm = this.formBuilder.group({
      NoteTypeID: [null, Validators.required],
      NoteBodyHtml: [null, [Validators.required, Validators.minLength(10)]],
      // Priority: [null, [this.showPriorities ? Validators.required : null]],
      //Title: [null, [this.showTitle ? Validators.required : null]]
    });

    if (this.showPriorities) {
      this.noteForm.addControl(
        'Priority',
        new FormControl(null, Validators.required)
      );
    }
    
    if (this.showTitle) {
      this.noteForm.addControl(
        'Title',
        new FormControl(null, Validators.required)
      );
    }

    if (!this.noteDto) {
      this.notesService.createNote().subscribe(
        (x) => {
          
          this.noteDto = x;
          this.priorities = x.Priorities;

          this.noteForm.patchValue(
            { NoteTypeID: this.NoteType ? this.NoteType.NoteTypeID : x.Note.NoteTypeID,
            NoteBodyHtml: x.Note.NoteBodyHtml,
          });

          
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      this.noteForm.patchValue({ 
        Priority: this.noteDto?.Note?.NotePriority,
        Title: this.noteDto?.Note.Title,
        NoteBodyHtml: this.noteDto.Note.NoteBodyHtml,
        NoteTypeID: this.noteDto.Note.NoteTypeID
        
       });
    }
  }

  onEditorCreated(editor: any) {
    this.editorInstance = editor;
  }

  doSave() {
    if (this.noteForm.valid) {

      const formData = this.noteForm.value;

      //TODO: fix hacks for single object with consistency
      this.noteDto.Note.ImplementationEstimateID = this.ImplementationEstimate.ImplementationEstimateID;
      this.noteDto.Note.NoteTypeID = formData.NoteTypeID as number;
      this.noteDto.Note.NoteBodyHtml = formData.NoteBodyHtml;
      this.noteDto.Note.NoteBodyPlain = this.editorInstance.getText();
      this.noteDto.Note.NotePriority = formData.Priority;
      this.noteDto.Note.Title = formData.Title;

      const dto = {
        Note: this.noteDto.Note,
        Attachments: this.attachments,
        NoteTypes: [],
        Priorities: [],
      };

      console.log(dto);

      this.notesService.saveNote(dto).subscribe(
        (response) => {
          this.toasterService.success('Note created');
          this.bsModalRef.hide();
        },
        (error) => {
          this.toasterService.error('An error occurred.');
          console.debug(error);
        }
      );
    }
  }

  onNoteTypeChanged() {}

  onPriorityChanged() {}

  openAttachmentModal() {
    const initialState = {
      ImplementationEstimate: this.ImplementationEstimate,
      associationType: 'implementation',
    };

    this.modalRef = this.modalService.show(AddAttachmentModalComponent, {
      class: 'modal-lg',
      initialState: initialState,
    });

    this.modalRef.content.attachmentAdded.subscribe((attachment) =>
      this.attachments.push(attachment)
    );

    this.modalRef.content.uploadInProgress.subscribe((inprogress) => {
      console.log('uploadinprogress', inprogress);
      this.isLoading = inprogress;
    })
  }

  deleteAttachment(attachmentID: number) {
    this.attachmentService.deleteAttachment(attachmentID).subscribe((x) => {
      this.attachments = this.attachments.filter((y) => {
        y.AttachmentID != attachmentID;
      });
    });
  }

  closeModal() {
    this.bsModalRef.hide();
  }
}
