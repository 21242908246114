import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { faCircle as faCircleOpen } from '@fortawesome/free-regular-svg-icons';
import { faCircle as faCircleClosed } from '@fortawesome/free-solid-svg-icons';
import { faCircleArrowDown } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Tooltip } from 'primeng/tooltip';
import { MatColumn } from 'src/app/grid/material-grid/material-grid.component';
import { NotesService } from 'src/app/services/notes.service';
import { UtilityService } from 'src/app/services/utility.service';
import { NoteEntryModalComponent } from '../note-entry-modal/note-entry-modal.component';

@Component({
  selector: 'app-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.scss'],
})
export class NotesListComponent implements OnInit {
  @Input() implementationEstimateID: number;
  @Input() listingType: string = 'display';
  @Input() listingValue: string = null;
  @Input() noteType: string;
  @Input() showTimeline: boolean = false;
  @Input() implementation: any;

  isLoading: boolean;
  data: any;
  columns: MatColumn[];
  modalRef?: BsModalRef;


  faCircleOpen = faCircleOpen;
  faCircleClosed = faCircleClosed;

  //@ViewChild('customTooltip') customTooltip: any;
  @ViewChild('customTooltip', { static: true }) customTooltip: TemplateRef<any>;
  tooltipTemplate: TemplateRef<any>; // This will be used dynamically

  constructor(
    private utilityService: UtilityService,
    private notesService: NotesService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  refresh() {
    this.loadData();
  }

  loadData() {
    this.isLoading = true;


    if (this.listingType == 'display') {
      this.notesService
        .implementationNotes(this.implementationEstimateID)
        .subscribe((x) => this.parseResult(x));
    }

    if (this.listingType == 'name') {
      this.notesService
        .getByNoteTypeName(this.implementationEstimateID, this.listingValue)
        .subscribe((x) => this.parseResult(x));
    }

    if (this.listingType == 'category') {
      this.notesService
        .getByCategory(this.implementationEstimateID, this.listingValue)
        .subscribe((x) => this.parseResult(x));
    }
  }

  openEditModal($event) {

    const initialState = {
      ImplementationEstimate: this.implementation,
      NoteTypeID: null,
      noteDto: $event
    };

    this.modalRef = this.modalService.show(NoteEntryModalComponent, {
      class: 'modal-xl',
      initialState: initialState,
    });

    this.modalRef.onHide.subscribe((x) => {
   
    });

  }

  getTooltipContent(note: any): string {

    // return `
    //   <div class="tooltip-card">
    //     <h5 class="card-title">${note.NoteType.Name}</h5>
    //     <h6 class="card-subtitle mb-2 text-muted">${note.CreatedByUserID}</h6>
    //     <p class="card-text"><small>${new Date(note.DateCreated).toLocaleDateString()}</small></p>
    //     <p class="card-text">${note.NoteBodyHtml}</p>
    //   </div>
    // `;

    // this.tooltipTemplate = this.customTooltip;
    // return this.tooltipTemplate;


    return `${note.NoteType.Name}: ${note.NoteBodyPlain}`; // Or use HTML content


  }

  private parseResult(x: any) {
    this.data = x.Data;
    this.columns = this.utilityService.getGridColumns(
      x.GridConfig?.GridColumns
    );
    this.isLoading = false;
  }

  
}
