import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class PreferenceService {
  constructor(protected http: HttpService) {}

  getUserPreferences(): Observable<any> {
    return this.http.get(`{api}UserPreference`);
  }

  saveUserPreferences(dto: any): Observable<any> {
    return this.http.post(`{api}UserPreference`, dto);
  }

}
