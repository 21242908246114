

import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { Observable, throwError, empty } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router, ActivationEnd } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Directive()
@Injectable()
export class HttpService {
  onError: any;

  @Output() requestPerformed = new EventEmitter<any>();

  constructor(private httpClient: HttpClient) {
    const that = this;
    this.onError = (error: HttpErrorResponse) => {
      console.log('onError', error);
      if (error.error instanceof ErrorEvent) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error('An error occurred', error);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong.
        console.error(`Backend returned code ${error.status}`, error);
      }

      return throwError(error);
    };
  }

  emitRequestPerformed() {
    this.requestPerformed.emit();
  }

  // Performs requests without firing RequestPerformed event (does not reset idle timer)
  getBackgroundTask(url: string, options?: any): Observable<any> {
    return this.httpClient
      .get(this.getFullUrl(url), this.requestOptions(options))
      .pipe(catchError(this.onError));
  }

  get(url: string, options?: any): Observable<any> {
    this.emitRequestPerformed();
    return this.httpClient
      .get(this.getFullUrl(url), this.requestOptions(options))
      .pipe(catchError(this.onError));
  }

  getNoAuth(url: string, options?: any): Observable<any> {
    this.emitRequestPerformed();
    return this.httpClient
      .get(this.getFullUrl(url), this.requestOptionsNoHeaders(options))
      .pipe(catchError(this.onError));
  }

  post(url: string, body?: any, options?: any): Observable<any> {
    this.emitRequestPerformed();
    return this.httpClient
      .post(this.getFullUrl(url), body, this.requestOptions(options))
      .pipe(catchError(this.onError));
  }

  postNoAuth(url: string, body?: any, options?: any): Observable<any> {
    this.emitRequestPerformed();
    return this.httpClient
      .post(this.getFullUrl(url), body, this.requestOptionsNoHeaders(options))
      .pipe(catchError(this.onError));
  }

  put(url: string, body?: any, options?: any): Observable<any> {
    this.emitRequestPerformed();
    return this.httpClient
      .put(this.getFullUrl(url), body, this.requestOptions(options))
      .pipe(catchError(this.onError));
  }

  patch(url: string, body: any, options?: any): Observable<any> {
    this.emitRequestPerformed();
    return this.httpClient
      .patch(this.getFullUrl(url), body, this.requestOptions(options))
      .pipe(catchError(this.onError));
  }

  delete(url: string, options?: any): Observable<any> {
    this.emitRequestPerformed();
    return this.httpClient
      .delete(this.getFullUrl(url), this.requestOptions(options))
      .pipe(catchError(this.onError));
  }

  requestOptions(options?: any): any {
    if (options == null) {
      options = { params: null };
    }
    const reqOptions = {
      headers: this.getHeaders(options),
      params: options.params,
    };
    return reqOptions;
  }

  requestOptionsNoHeaders(options?: any): any {
    if (options == null) {
      options = { params: null };
    }
    return {
      params: options.params,
    };
  }

  getHeaders(options?: any): HttpHeaders {
    let headers: HttpHeaders;
    if (options && options.headers != null) {
      headers = options.headers;
    } else {
      headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
    }

    // if (typeof this.token !== 'undefined') {
    //   headers = headers.append('Authorization', `Bearer ${this.token}`);
    // }

    return headers;
  }

  getFullUrl(url: string): string {
    url = url.replace('{api}', environment.apiEndpoint);
    url = url.replace('{server}', environment.serverEndpoint);
    url = url.replace('%7B', '/');
    url = url.replace('%7D', '\\');
    return url;
  }
}
