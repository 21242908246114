import { Injector, Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filterByRole',
  pure: true,
})
export class FilterByRolePipe implements PipeTransform {
  public constructor(private readonly injector: Injector) {}

  transform(value: Array<any>, roleID: any): any {
    let result = value.filter(x => x.Role.RoleID == roleID);
    return result;
  }
}
