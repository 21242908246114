import { Component, OnInit } from '@angular/core';
import { AzureadService } from '../services/azuread.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  loginDisplay = false;
  profile: any;

  constructor(private azuread: AzureadService) {}

  ngOnInit(): void {

    // this.azuread.checkAndSetActiveAccount();
    // this.azuread.getProfile().subscribe((p) => {
    //   if (p.account?.username) {
    //     this.profile = p;
    //     this.loginDisplay = true;
    //   }
    // });
  }
}
