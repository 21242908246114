

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(protected http: HttpService) {}

  //templates
  getAllTemplates(): Observable<any[]> {
      return this.http.get(`{api}templates`);
  }

  getTemplate(id: any): Observable<any[]> {
    return this.http.get(`{api}templates/${id}`);
  }

  createTemplate(dto: any): Observable<any> {
    return this.http.post(`{api}templates/create`, dto);
  }

  deleteTemplate(id: any): Observable<any> {
    return this.http.delete(`{api}templates/${id}`);
  }

  getTemplateEstimates(id: any): Observable<any[]> {
    return this.http.get(`{api}templates/template-estimates/${id}`);
  }

  saveEstimates(id: any, estimates: any): Observable<any> {
    return this.http.post(`{api}templates/${id}`, estimates);
  }


  getImplementationEstimateList(): Observable<any> {
    return this.http.get(`{api}implementations/implementations-list`);
  }


  addTemplateToImplementationAssociation(params: any): Observable<any> {
    const url = `{api}templates/assign-implementation`
    return this.http.post(url, params);
  }

  getProductTypes(): Observable<any[]> {
    return this.http.get(`{api}producttypes`);
  }

  //implementations
  deleteImplementationEstimate(id: any): Observable<any> {
    return this.http.delete(`{api}implementations/${id}`);
  }

  removeTemplateAssignment(id: any): Observable<any> {
    return this.http.put(`{api}implementations/remove-assignment/${id}`);
  }

  getImplementationEstimate(id: any): Observable<any> {
    return this.http.get(`{api}implementations/${id}`);
  }

  getImplementationEstimateByRoleMonth(id: any): Observable<any> {
    return this.http.get(`{api}implementations/by-role-month/${id}`);
  }

  getImplementationEstimateByDeliverable(id: any): Observable<any> {
    return this.http.get(`{api}implementations/by-deliverable/${id}`);
  }

  getImplementationEstimateByDeliverableRole(id: any): Observable<any> {
    return this.http.get(`{api}implementations/by-deliverable-role/${id}`);
  }

  saveOverrides(id: any, overrides: any): Observable<any> {
    return this.http.post(`{api}implementations/save-overrides/${id}`, JSON.stringify(overrides));
  }

  //time entry
  getTimeEntryCreate(): Observable<any> {
    return this.http.get(`{api}timeentry`);
  }

  createTimeEntry(timeEntry: any): Observable<any> {
    return this.http.post(`{api}timeentry`, JSON.stringify(timeEntry));
  }

  deleteTimeEntry(entryID: any): Observable<any> {
    return this.http.delete(`{api}timeentry/${entryID}`);
  }

  getPriorEntries(): Observable<any> {
    return this.http.get(`{api}timeentry/prior-entries`);
  }

  getReport(implementationID: any): Observable<any> {
    return this.http.get(`{api}timeentry/implementation-report/${implementationID}`);
  }

  getTimesheetReviewConfig(): Observable<any> {
    return this.http.get(`{api}timeentry/timesheet-review`);
  }

  submitTimesheet(timesheet): Observable<any> {
    return this.http.post(`{api}timeentry/submit-timesheet`, JSON.stringify(timesheet))
  }


  //reports
  getReportBase() : Observable<any> {
    return this.http.get(`{api}reports`);
  }

  getReportCsv(body: any):  Observable<any> {
    return this.http.post(`{api}reports`, body);
  }

  getRoles(): Observable<any[]> {
    return this.http.get(`{api}roles`);
  }


  getDeliverables(): Observable<any[]> {
    return this.http.get(`{api}deliverables`);
  }

  saveDeliverable(deliverable: any): Observable<any[]> {
    return this.http.post(`{api}deliverables`, JSON.stringify(deliverable));
  }

  getInternalTimeCategories(): Observable<any[]> {
    return this.http.get(`{api}internaltimecategories`);
  }

  setInternalTimeCategory(internalTimeCategory: any): Observable<any> {
    return this.http.put(`{api}internaltimecategories/${internalTimeCategory.InternalTimeCategoryID}`, JSON.stringify(internalTimeCategory));
  }

 
}
