<div class="row p-1">

  <div class="d-flex justify-content-end">
    <span>Make selections and click save to set default implementations: <button class="btn btn-primary" (click)="saveChanges()" [disabled]="!hasChanges">Save Changes</button></span>


  </div>
</div>

<div class="row">
  <div *ngIf="config" class="list-group">
    <div *ngFor="let implementation of config.AllImplementationEstimates" class="list-group-item">
      <input class="form-check-inline" type="checkbox"
             [id]="implementation.ImplementationEstimateID"
             [checked]="selectedImplementations.includes(implementation.ImplementationEstimateID)"
             (change)="toggleImplementation(implementation.ImplementationEstimateID)">
      <label [for]="implementation.ImplementationEstimateID">{{ implementation.SFImplementationName }}</label>
    </div>
</div>

