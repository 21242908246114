import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utcToLocalDate'
})
export class UtcToLocalDatePipe implements PipeTransform {
  transform(value: string | Date, format: string = 'MM-dd-yyyy h:mm a'): string {
    if (!value) return '';

    const date = new Date(value);
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return '';
    }

    // Convert to local date
    const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));

    // Format the date
    return this.formatDate(localDate, format);
  }

  private formatDate(date: Date, format: string): string {
    const options: Intl.DateTimeFormatOptions = {};

    if (format.includes('MM-dd-yyyy')) {
      options.year = 'numeric';
      options.month = '2-digit';
      options.day = '2-digit';
    }

    if (format.includes('h:mm a')) {
      options.hour = '2-digit';
      options.minute = '2-digit';
      options.hour12 = true;
    }

    return new Intl.DateTimeFormat('en-US', options).format(date);
  }
}
