import { Component, OnInit } from '@angular/core';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AzureadService } from 'src/app/services/azuread.service';
import { DataService } from 'src/app/services/data.service';
import { TimeEntryEditModalComponent } from '../time-entry-edit-modal/time-entry-edit-modal.component';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';

interface TimeEntryModalInitialState {
  timeEntry: any;
}

@Component({
  selector: 'app-timesheet-review',
  templateUrl: './timesheet-review.component.html',
  styleUrls: ['./timesheet-review.component.scss'],
})
export class TimesheetReviewComponent implements OnInit {
  bsModalRef?: BsModalRef;

  config: any;
  profile: any;
  timeEntries: any[] = [];
  selectedTimesheet: any;
  lastDayOfMonth: any;

  faPen = faPen;

  constructor(
    private dataService: DataService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private azuread: AzureadService,
    private modalService: BsModalService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.azuread.getProfile().subscribe((profile) => (this.profile = profile));

    this.route.queryParams.subscribe((params) => {
      const dateParam = params['date'];

      if (dateParam) {
        this.load();
      }
    });

    this.load();
  }

  load(): void {
    let dateParam = null;
    this.route.queryParams.subscribe((params) => {
      dateParam = params['date'];
    });

    this.spinnerService.show();
    this.dataService.getTimesheetReviewConfig().subscribe(
      (result) => {
        this.config = result;

        if (dateParam) {
          this.config.SelectedTimesheetMonthEnd = dateParam;
        }

        this.fixDates();
        this.filterTimeEntriesByMonthEnd();
        this.spinnerService.hide();
      },
      (err) => console.error(err)
    );
  }

  fixDates() {
    this.config.SelectedTimesheetMonthEnd = moment.utc(
      this.config.SelectedTimesheetMonthEnd
    ).format('YYYY-MM-DD');



    this.config.Timesheets.forEach((element) => {
      element.MonthEnding = moment.utc(element.MonthEnding).format(
        'YYYY-MM-DD'
      );
    });

    this.config.TimeEntries.forEach((element) => {
      element.EntryDate = moment.utc(element.EntryDate).format(
        'YYYY-MM-DD'
      );
    });


  }

  filterTimeEntriesByMonthEnd() {
    //console.log(this.lastDayOfMonth);
    // if (this.lastDayOfMonth) {
    //   this.config.SelectedTimesheetMonthEnd = this.lastDayOfMonth;
    // }

    //hack due to timezones
    this.config.SelectedTimesheetMonthEnd = moment.utc(
      this.config.SelectedTimesheetMonthEnd
    ).format('YYYY-MM-DD');

    this.timeEntries = this.config.TimeEntries.filter((entry) => {
      const lastDayOfMonthVal = moment.utc(entry.LastDayOfMonth).format(
        'YYYY-MM-DD'
      );

      this.lastDayOfMonth = lastDayOfMonthVal;
      // Check if the entry's date matches the last day of the month
      return this.config.SelectedTimesheetMonthEnd == lastDayOfMonthVal;

    });

    this.selectedTimesheet = this.filterTimesheetsByMonthEnd();
  }

  filterTimesheetsByMonthEnd(): any {


    //hack due to timezones
    const filterCriteria = moment.utc(this.config.SelectedTimesheetMonthEnd).format(
      'YYYY-MM-DD'
    );

    let timesheet = this.config.Timesheets.find((entry) => {
      const lastDayOfMonth = moment.utc(entry.MonthEnding).format(
        'YYYY-MM-DD'
      );

      // Check if the entry's date matches the last day of the month
      return filterCriteria == lastDayOfMonth;
    });

    return timesheet;
  }

  openTimeEntryModal(selectedEntry: any) {
    const initialState: TimeEntryModalInitialState = {
      timeEntry: selectedEntry,
    };

    const modalConfig: ModalOptions = {
      initialState: initialState as any,
      class: 'modal-lg',
    };

    this.bsModalRef = this.modalService.show(
      TimeEntryEditModalComponent,
      modalConfig
    );

    this.bsModalRef.content.onClose.subscribe(() => {
      this.load();
    });
  }

  openTimesheetSubmitModal(template: any) {
    this.bsModalRef = this.modalService.show(template);
  }

  doSubmit() {
    this.spinnerService.show();

    const currentMonth = this.config.SelectedTimesheetMonthEnd;
    console.debug(currentMonth);

    this.dataService.submitTimesheet(this.selectedTimesheet).subscribe(
      (result) => {
        this.config = result;

        this.fixDates();
        this.filterTimeEntriesByMonthEnd();

        //set date to last timesheet submitted
        this.config.SelectedTimesheetMonthEnd = currentMonth;

        this.bsModalRef.hide();

        this.spinnerService.hide();
      },
      (err) => {
        alert(err.error);
        this.bsModalRef.hide();

        this.spinnerService.hide();
      }
    );

  }
}
