import { Component, OnInit } from '@angular/core';
import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AzureadService } from 'src/app/services/azuread.service';
import { DataService } from 'src/app/services/data.service';
import { CategoriesModalComponent } from '../categories-modal/categories-modal.component';

interface CategoriesModalInitialState {
  selectedCategory: any;
  categoryName: any;
}

@Component({
  selector: 'app-categories-editor',
  templateUrl: './categories-editor.component.html',
  styleUrls: ['./categories-editor.component.scss'],
})
export class CategoriesEditorComponent implements OnInit {
  bsModalRef?: BsModalRef;
  categories: any;
  selectedCategory: any;

  faPen = faPen;
  faPlus = faPlus;

  constructor(
    private dataService: DataService,
    private spinnerService: NgxSpinnerService,
    private toastr: ToastrService,
    private azuread: AzureadService,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.refresh();
  }

  private refresh() {
    this.spinnerService.show();

    this.dataService.getInternalTimeCategories().subscribe(
      (y) => {
        this.categories = y;
      },
      (err) => {
        if (err.status && err.status == "403") {
          this.handleError(err);
        }
        console.error(err);

      }
    );

    this.spinnerService.hide();

  }

  openConfigModal(category: any) {
    const initialState: CategoriesModalInitialState = {
      selectedCategory: category,
      categoryName: category.Name,
    };

    const modalConfig: ModalOptions = {
      initialState: initialState as any,
      class: 'modal-lg', // Set the modal size to large
    };

    this.bsModalRef = this.modalService.show(CategoriesModalComponent, modalConfig);
    this.bsModalRef.content.onClose.subscribe(() => {
      this.refresh();
    });
  }

  handleError(err) {
    alert("You are not authorized to view this page. Please contact an administrator for assistance.")
  }

}
