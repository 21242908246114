<div class="container-fluid">
  <div class="row">
    <div class="col-8">
      <h3>Template Assignments</h3>
    </div>
    <div class="col-4 d-flex justify-content-end">
      <p><fa-icon class="text-secondary" [icon]="faInfoCircle"></fa-icon>&nbsp; Assign implementations to templates or edit estimates</p>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-12">
      <div class="table-responsive table-wrapper-scroll-y my-custom-scrollbar">
        <table class="table table-bordered table-striped mb-0">
          <thead>
            <tr>
              <th scope="row">ID</th>
              <th scope="row">Product</th>
              <th scope="row">Name</th>
              <th scope="row">Estimated Hours</th>
              <!-- <th></th>
            <th></th> -->
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let template of templates">
              <tr style="border-top: 2px solid">
                <th scope="row">{{template.TemplateID}}</th>
                <td>{{template.ProductType.Name}}</td>
                <td>{{template.Name}}</td>
                <td>{{template.TotalHours}}</td>
                <td>
                  <a class="btn btn-sm btn-outline-secondary" style="margin-right: 5px;"
                    [routerLink]="['../template/'+ template.TemplateID]" tooltip="Edit Template"><fa-icon
                      [icon]="faPen"></fa-icon> Template</a>
                </td>
                <td>
                  <button class="btn btn-sm btn-outline-primary" (click)="showImplementationAssignmentModal(template)">
                    <fa-icon [icon]="faPlus"></fa-icon> &nbsp; Implementation
                  </button>
                <td>
              </tr>
              <tr *ngIf="template?.ImplementationEstimates.length > 0">
                <td colspan="6">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <th>Implementations:</th>
                      <th>Estimated Hours</th>
                      <th></th>
                    </thead>
                    <tbody>
                      <tr *ngFor="let implementation of template.ImplementationEstimates">
                        <td>{{ implementation.SFImplementationName }}</td>
                        <td>{{ implementation.TotalHours }}</td>
                        <td>
                          <div>
                            <button class="btn btn-sm btn-outline-secondary m-l"
                              [routerLink]="['/implementations/'+ implementation.ImplementationEstimateID]"
                              tooltip="Edit Overrides">
                              <fa-icon [icon]="faPen"></fa-icon>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr *ngIf="!template">
                <td colspan="6">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#ffeb3b" type="pacman" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
</div>