import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PreferenceService } from 'src/app/services/preferences.service';

@Component({
  selector: 'app-preference-settings',
  templateUrl: './preference-settings.component.html',
  styleUrls: ['./preference-settings.component.scss'],
})
export class PreferenceSettingsComponent implements OnInit {
  config: any;
  selectedImplementations: number[] = [];
  originalImplementations: number[] = [];

  hasChanges = false;

  @Output() preferencesSaved = new EventEmitter<any>();

  constructor(private preferenceService: PreferenceService) {}

  ngOnInit(): void {
    this.preferenceService.getUserPreferences().subscribe((x) => {
      this.config = x;

      if (x.ImplementationEstimateIDs != null) {
        this.selectedImplementations = x.ImplementationEstimateIDs;
      }
    });
  }

  toggleImplementation(implementationID: number) {
    const index = this.selectedImplementations.indexOf(implementationID);
    if (index === -1) {
      this.selectedImplementations.push(implementationID);
    } else {
      this.selectedImplementations.splice(index, 1);
    }

    this.hasChanges = true;
    this.config.ImplementationEstimateIDs = this.selectedImplementations;
  }

  saveChanges() {
    this.preferenceService.saveUserPreferences(this.config).subscribe(() => {
      this.preferencesSaved.emit();
    });
  }
}
